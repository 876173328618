
























import useFeatureFlag from '@/compositions/useFeatureFlag'
import CarrierSuggestionsModal from './CarrierSuggestionsModal.vue'
import DeprecatedCarrierSuggestionsModal from './DeprecatedCarrierSuggestionsModal.vue'
import { ENABLE_CARRIER_SUGGESTIONS_WITH_LOOKER } from '../constants'
import { PropType } from 'vue'
import { LoadDetails } from './CarrierSearchLookerDashboard'
import { DispatchingOrderDetail } from '../../types'
import { OrderStop } from '@/services'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    CarrierSuggestionsModal,
    DeprecatedCarrierSuggestionsModal
  },
  props: {
    category: { type: String, required: false, default: '' },
    loadDetails: { type: Object as PropType<LoadDetails>, required: true },
    order: { type: Object as PropType<DispatchingOrderDetail>, required: true },
    stops: { type: Array as PropType<OrderStop[]>, required: true },
    disabled: { type: Boolean, default: false }
  },
  setup() {
    const { isActive } = useFeatureFlag()
    const enableCarrierSuggestionsWithLooker = isActive(
      ENABLE_CARRIER_SUGGESTIONS_WITH_LOOKER
    )

    function onClick() {
      if (enableCarrierSuggestionsWithLooker.value) {
        this.$refs.modal.open()
      } else {
        this.$refs.deprecatedModal.open()
      }
    }

    return {
      onClick
    }
  }
})
