import { render, staticRenderFns } from "./CarrierSuggestionsButton.vue?vue&type=template&id=0d70644c&scoped=true&"
import script from "./CarrierSuggestionsButton.vue?vue&type=script&lang=ts&"
export * from "./CarrierSuggestionsButton.vue?vue&type=script&lang=ts&"
import style0 from "./CarrierSuggestionsButton.vue?vue&type=style&index=0&id=0d70644c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d70644c",
  null
  
)

export default component.exports