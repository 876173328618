import { CARRIER_PROFILE_API_URL } from '@/config'
import { genericClient } from '../clients/generic.client'
import { Carrier, Contact } from '@sennder/carrier-profile-axios-client'

export async function getCarrier(carrierId: string): Promise<Carrier> {
  const response = await genericClient.get<Carrier>(
    `${CARRIER_PROFILE_API_URL}/public/v1/carriers/${carrierId}`
  )

  return response.data
}

export async function getCarrierContact(contactId: string): Promise<Contact> {
  const response = await genericClient.get<Contact>(
    `${CARRIER_PROFILE_API_URL}/public/v1/contacts/${contactId}`
  )

  return response.data
}
