import { monitorError } from '@/analytics/monitoring'
import {
  getFacilityPlan,
  getFacilityProfile
} from '@/api/services/facility-management.service'
import { FacilityPlan, FacilityProfile } from '@/types/facility-management'
import { ref, computed } from '@vue/composition-api'
import { AxiosError } from 'axios'
import { defineStore } from 'pinia'

export const useFacilityManagementStore = defineStore('facility-management-store', () => {
  // state
  const loaders = ref<string[]>([])
  const facilityPlan = ref<FacilityPlan | null>(null)
  const facilityProfiles = ref<FacilityProfile[]>([])

  // getters
  const isLoading = computed<boolean>(() => loaders.value.length > 0)

  // actions
  async function fetchFacilityPlan(shipment_id: string, showLoader = true) {
    try {
      if (showLoader) loaders.value.push('facilityPlan')
      facilityPlan.value = await getFacilityPlan(shipment_id)
    } catch (err: unknown) {
      // plan may not exist for the shipment, this is not an error
      if ((err as AxiosError).response?.status === 404) return

      monitorError(new Error('Failed to fetch facility plan'), 'octopus-new-store', {
        err
      })

      throw err
    } finally {
      loaders.value = loaders.value.filter(loader => loader !== 'facilityPlan')
    }
  }

  async function fetchFacilityProfiles(profileIds: string[], showLoader = true) {
    if (showLoader) loaders.value.push('facilityProfiles')

    return Promise.all(profileIds.map(profileId => getFacilityProfile(profileId)))
      .then(fetchedFacilityProfiles => {
        facilityProfiles.value = fetchedFacilityProfiles
      })
      .catch(err => {
        monitorError(
          new Error('Failed to fetch facility profiles'),
          'octopus-new-store',
          {
            err
          }
        )

        throw err
      })
      .finally(() => {
        loaders.value = loaders.value.filter(loader => loader !== 'facilityProfiles')
      })
  }

  return {
    loaders,
    isLoading,
    facilityPlan,
    facilityProfiles,
    fetchFacilityPlan,
    fetchFacilityProfiles
  }
})
