import { computed, reactive, Ref } from '@vue/composition-api'
import {
  DispatchingTransferDetail,
  FormValues
} from '@/modules/ordering/components/dispatching/types'

export default (transfer: Ref<DispatchingTransferDetail>) => {
  const licensePlate = computed<string>({
    get: () => {
      return transfer.value?.licensePlate?.trim() || ''
    },
    set: val => {
      if (transfer.value) transfer.value.licensePlate = val
    }
  })

  const trailerLicensePlate = computed<string>({
    get: (): string => {
      return transfer.value?.trailerLicensePlate?.trim() || ''
    },
    set: val => {
      if (transfer.value) transfer.value.trailerLicensePlate = val
    }
  })

  const formValues = reactive<FormValues>({
    allowedVehicleTypes: null,
    carrierCompany: null,
    carrierContact: null,
    notes: null,
    vehicleType: null,
    licensePlate: licensePlate,
    trailerLicensePlate: trailerLicensePlate,
    transferCode: null,
    driverName: '',
    driverPhoneNumber: '',
    gatehousePullOn: false,
    gatehousePushOn: false,
    amazonPushOn: false,
    baseCost: null,
    costSurcharge: null,
    costSurchargeDescription: null,
    partnerManager: null,
    dispatchedVehicle: null,
    co3: {
      vehicle: false,
      trailer: false
    },
    gatehouse: {
      vehicle: false,
      trailer: false
    },
    driverApp: {
      vehicle: false,
      trailer: false
    }
  })

  const usesDriverApp = computed<boolean>(() => Boolean(formValues.licensePlate))

  return {
    formValues,
    usesDriverApp,
    //for testing
    licensePlate,
    trailerLicensePlate
  }
}
