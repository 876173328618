
































































































































































































import moment from 'moment-timezone'
import { PropType, defineComponent } from '@vue/composition-api'
import get from 'lodash/get'
import {
  OrderStop,
  OrderDistanceTransitTime,
  TransferStepActualTime,
  ShipmentV3OrderStop
} from '@/services'
import upperFirst from 'lodash/upperFirst'
import lowerCase from 'lodash/lowerCase'
import startCase from 'lodash/startCase'
import { googleMapUrl } from '@/modules/common/googlemap-stops-helpers'
import LabeledText from '@/modules/common/components/LabeledText.vue'
import { getTimezoneAcronym } from '@/global-setup/filters'

export default defineComponent({
  components: { LabeledText },
  props: {
    stops: { type: Array, required: true },
    steps: { type: Array as PropType<TransferStepActualTime[]>, required: true },
    multipleStopLetters: { type: Array as PropType<string[]>, required: true },
    stopsRouteDetails: {
      type: Array as PropType<OrderDistanceTransitTime[]>,
      required: true
    },
    isOrderDispatched: { type: Boolean, required: true },
    isTotalValid: { type: Boolean, required: true },
    hasStepsLoaded: { type: Boolean, required: true }
  },
  setup() {
    const stopAddressText = (stop: ShipmentV3OrderStop) => {
      if (!stop?.warehouseAddress) return ''

      const { description, fullAddress } = stop.warehouseAddress
      if (fullAddress) {
        return description ? `${fullAddress}, ${description}` : fullAddress
      }

      return ''
    }

    const hasFacilityRequirements = (stop: ShipmentV3OrderStop): boolean => {
      return (
        !!stop.side ||
        !!stop.drop_trailer ||
        !!stop.driver_assistance ||
        !!(
          stop.personal_protective_equipment &&
          Object.values(stop.personal_protective_equipment).some(Boolean)
        )
      )
    }

    const getRoundedTime = (transitTimeToNext: number | undefined) => {
      if (transitTimeToNext) {
        const hours = Math.floor(transitTimeToNext / 3600)
        const minutes = Math.floor((transitTimeToNext % 3600) / 60)
        return `${hours}h ${minutes}min`
      } else {
        return null
      }
    }

    const getIsoTzAcronym = (isoTimestamp: string, timezone: string): string => {
      try {
        return getTimezoneAcronym(isoTimestamp, timezone)
      } catch (e) {
        return 'N/A'
      }
    }

    const getTzAcronym = (dateTimeString: string, timezone: string): string => {
      try {
        const momentObj = moment(dateTimeString)
        const formattedMoment = momentObj.tz(timezone)
        return formattedMoment.format('z')
      } catch (error) {
        return 'N/A'
      }
    }

    const getTzChipText = (stop: OrderStop) => {
      const warehouseTimezone: string = stop.warehouseAddress.timezone
      const tzAcronym = getTzAcronym(stop.arrivalToWarehouse, warehouseTimezone)
      return `${warehouseTimezone} Time (${tzAcronym})`
    }

    const formatDate = (date: string) => {
      // This format is specifically for this sidebar
      return moment(date, 'DD.MM.YYYY').format('DD.MM.YY')
    }

    const formatPersonalProtectiveEquipment = (stop: ShipmentV3OrderStop): string => {
      const ppe = stop.personal_protective_equipment ?? {}
      return Object.entries(ppe)
        .filter(([, value]) => value)
        .map(([key]) => startCase(key))
        .join(', ')
    }

    const formatFacilityRequirements = (stop: ShipmentV3OrderStop): string => {
      const requirements = [
        stop.driver_assistance && 'Driver Loading',
        stop.drop_trailer && 'Drop Trailer',
        stop.side && `${upperFirst(stop.side)} Loading`,
        formatPersonalProtectiveEquipment(stop)
      ].filter(Boolean)

      return requirements.join(' | ')
    }

    return {
      get,
      upperFirst,
      lowerCase,
      startCase,
      googleMapUrl,
      getRoundedTime,
      getTzChipText,
      getTzAcronym,
      getIsoTzAcronym,
      formatDate,
      hasFacilityRequirements,
      stopAddressText,
      formatPersonalProtectiveEquipment,
      formatFacilityRequirements
    }
  }
})
