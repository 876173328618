




































import { PropType, defineComponent } from '@vue/composition-api'
import { OrderDetail } from '@/services'
import { placeholderIfEmpty } from '@/global-setup/filters'
import LabeledText from '@/modules/common/components/LabeledText.vue'

export default defineComponent({
  components: { LabeledText },
  props: {
    order: { type: Object as PropType<OrderDetail>, required: true },
    loadQuantityAndType: { type: String, required: true },
    loadDimensions: { type: String, required: true },
    loadTemperatures: { type: String, default: null },
    vehicleTypes: { type: String, default: null },
    getRoundedTime: { type: Function, required: true }
  },
  setup() {
    return { placeholderIfEmpty }
  }
})
