import { render, staticRenderFns } from "./LoadSectionV3.vue?vue&type=template&id=b3213f72&scoped=true&"
import script from "./LoadSectionV3.vue?vue&type=script&lang=ts&"
export * from "./LoadSectionV3.vue?vue&type=script&lang=ts&"
import style0 from "./LoadSectionV3.vue?vue&type=style&index=0&id=b3213f72&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3213f72",
  null
  
)

export default component.exports