import { OPERATIONS_BACKEND_URL, OPERATORS_API_URL, SHIPMENT_API_URL } from '@/config'
import { SINGLE_AUDIENCE } from '@/services/auth-service'
import {
  runJsonGet,
  runJsonPut,
  runJsonPatch,
  runJsonDelete
} from '@/models/backend-client'
import { ref } from '@vue/composition-api'
import { useRouter } from 'vue2-helpers/vue-router'
import logger from '@/shell/console-logger'
import { AxiosResponse } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'

import {
  IShipment,
  IShipmentDraftPayloadData,
  IShipmentRoute,
  IShipperStopAddress
} from '../types/shipment-draft'
import {
  IMothershipContact,
  IMothershipCustomer,
  IMothershipContactWithCase
} from '../types/mothership'
import { transformKeysToCamelCase } from '@/services/utils/casing'
import { fullName } from '@/modules/common/filters/person-filters'
import {
  shipmentDraftPayloadFactory,
  updatedShipmentFactory
} from '../utils/shipment-factories'
import moment from 'moment-timezone'
import { fetchShipperProfile } from '@/services/ordering-service'
import { showToast } from '@/plugins/toaster/swalToaster'
import useStore from '@/compositions/useStore'
import {
  getDesignatedOperators,
  OperatorRoleId
} from '@/services/designated-operators-service'
import { createUtcByWarehouseTimezone } from '@/modules/shipment/utils/date-formats'
import { SHIPMENT_CREATION_MODE } from '@/modules/shipment/constants'

/**
 * Currently we allow user to continue to work with the order if shipment is already published.
 */
const redirectToOrder = true

interface PromiseFulfilledResult<T> {
  status: 'fulfilled'
  value: T
}

export const useShipment = () => {
  const shipment = ref<IShipment>(null)
  const customer = ref<IMothershipCustomer>(null)
  const contact = ref<IMothershipContactWithCase>(null)
  const isLoadShipmentDataInProgress = ref<boolean>(false)
  const error = ref<string>('')
  const isCreatingShipmentEditRequestInProgress = ref<boolean>(false)
  const isShipmentEditRequestSuccessful = ref<boolean>(false)
  const shipmentEditRequestId = ref<string>(null)
  const shipmentRoute = ref<IShipmentRoute[]>([])
  const subContractingPolicy = ref<string>(null)
  const shipperProfileaccountStatus = ref<string>('')
  const router = useRouter()
  const store = useStore()

  const orderContainsShipment = store.getters['ordering/orderContainsShipment']

  const getShipmentIdByOrderId = async (orderId: number) => {
    try {
      if (!orderContainsShipment) {
        await store.dispatch('ordering/fetchShipmentData', orderId)
      }
    } catch (error) {
      logger.warn(
        `Unable to execute setShipmentData, shipment service failed for order_id: ${orderId} ${error}`
      )
    }
    return store.getters['ordering/getShipmentID']
  }

  const fetchShipment = async (id: string) => {
    try {
      const response = (await runJsonGet(
        `${SHIPMENT_API_URL}/v2/shipments/${id}`,
        null,
        {
          apiGatewayAuthorization: true
        },
        'https://api.cloud.sennder.com/shipments',
        'shipments:read'
      )) as AxiosResponse<{
        shipment: IShipment
      }>

      return response.data.shipment
    } catch (e) {
      logger.warn('Error loading shipment!', e)
    }
  }

  const createShipmentEditRequest = async (
    payload,
    shipmentId: string
  ): Promise<IShipment> => {
    try {
      isCreatingShipmentEditRequestInProgress.value = true
      const response = (await runJsonPatch(
        `${SHIPMENT_API_URL}/v2/shipments/${shipmentId}`,
        payload,
        { apiGatewayAuthorization: true },
        'https://api.cloud.sennder.com/shipments',
        'shipments:create'
      )) as AxiosResponse
      isShipmentEditRequestSuccessful.value = true
      shipmentEditRequestId.value = response.data.id

      return response.data.id
    } catch (e) {
      isShipmentEditRequestSuccessful.value = false
      showToast(
        'Error! Something went wrong while sending the shipment edit request.',
        'warning'
      )

      logger.warn('Error updating shipment data', e)
    } finally {
      isCreatingShipmentEditRequestInProgress.value = false
    }
  }

  const fetchShipmentDraft = async (id: string) => {
    try {
      const response = (await runJsonGet(
        `${SHIPMENT_API_URL}/v2/shipment-drafts/${id}`,
        null,
        {
          apiGatewayAuthorization: true
        },
        'https://api.cloud.sennder.com/shipments',
        'shipments:read'
      )) as AxiosResponse<IShipment>

      return response.data
    } catch (e) {
      logger.warn('Error loading shipment draft!', e)
    }
  }

  const fetchCustomerData = async (id: number) => {
    try {
      const response = (await runJsonGet(
        `${OPERATIONS_BACKEND_URL}/ordering/queries/customer/${id}`
      )) as AxiosResponse<IMothershipCustomer>

      return response.data
    } catch (e) {
      logger.warn('Error loading customer!', e)
    }
  }

  const fetchContactData = async (id: number, customer_id: number) => {
    try {
      const response = (await runJsonGet(
        `${OPERATIONS_BACKEND_URL}/ordering/queries/get-customer-contacts?customer_id=${customer_id}`
      )) as AxiosResponse<IMothershipContact[]>

      // We need snake case for CustomerDetailsForm.vue...
      const contact = transformKeysToCamelCase(
        response.data.find(contact => contact.id === id)
      ) as IMothershipContactWithCase

      return {
        ...contact,
        name: fullName(contact)
      }
    } catch (e) {
      logger.warn('Error loading contact!', e)
    }
  }

  const loadShipmentData = async (
    id: string,
    isShipmentDraft = true,
    shouldFetchContactInfo = true
  ) => {
    let shipmentData
    isLoadShipmentDataInProgress.value = true

    try {
      if (isShipmentDraft) shipmentData = await fetchShipmentDraft(id)
      else {
        shipmentData = await fetchShipment(id)
      }

      if (shipmentData) {
        shipment.value = shipmentData
        customer.value = await fetchCustomerData(shipmentData.shipper.shipper_id)
        if (shouldFetchContactInfo) {
          contact.value = await fetchContactData(
            shipmentData.shipper.contact_id,
            shipmentData.shipper.shipper_id
          )
        }
        const { loading_requirements, unloading_requirements } =
          shipmentData.loads[0].load_requirements

        const shipperProfile = await fetchShipperProfile(
          shipmentData.shipper.shipper_id.toString()
        )
        if (shipperProfile?.length > 0) {
          subContractingPolicy.value = shipperProfile[0].subContractingPolicy
          shipperProfileaccountStatus.value = shipperProfile[0]?.accountStatus
        }

        if (loading_requirements.length && unloading_requirements.length) {
          const customerIdsSet = new Set()

          await Promise.all(
            loading_requirements.map(async el => {
              const warehouseAddress = await fetchWarehouseDetailsById(
                el.warehouse_address_id
              )
              el.warehouseAddress = warehouseAddress?.address
              customerIdsSet.add(el.warehouse_address_id)
            })
          )

          await Promise.all(
            unloading_requirements.map(async el => {
              const warehouseAddress = await fetchWarehouseDetailsById(
                el.warehouse_address_id
              )
              el.warehouseAddress = warehouseAddress?.address
              customerIdsSet.add(el.warehouse_address_id)
            })
          )

          const results = await Promise.allSettled(
            Array.from(customerIdsSet).map(async id => fetchWarehouseDetailsById(id))
          )

          const mappedResults = results
            .filter(({ status }) => status === 'fulfilled')
            .map(p => (p as PromiseFulfilledResult<IShipperStopAddress>).value)

          const allStops = [...loading_requirements, ...unloading_requirements]

          shipmentRoute.value = allStops.map(stop => {
            const match = mappedResults.find(({ warehouse_address_id }) => {
              return stop.warehouse_address_id === warehouse_address_id
            })

            return {
              postalCode: match.address.postal_code,
              countryCode: match.address.country_code
            }
          })
        }
        return
      }

      const publishedShipmentDraft = await fetchShipment(id)

      if (publishedShipmentDraft) {
        if (redirectToOrder) {
          router.push(
            `/ordering/${publishedShipmentDraft.shipment_meta.order_id_for_staff}`
          )
        } else {
          shipment.value = publishedShipmentDraft
        }

        return
      }

      throw new Error('No Shipment detected')
    } catch (e) {
      logger.warn('Error loading shipment data!', e)
      error.value = `There is no shipment with provided id: ${id}`
    } finally {
      isLoadShipmentDataInProgress.value = false
    }
  }

  const getCustomHeaders = mode => ({
    'x-client-name': mode
  })

  const createShipmentDraft = async (
    params: IShipmentDraftPayloadData,
    mode = SHIPMENT_CREATION_MODE.MANUAL
  ): Promise<IShipment> => {
    const customHeaders = getCustomHeaders(mode)

    try {
      const response = (await runJsonPut(
        `${SHIPMENT_API_URL}/v2/shipment-drafts/${params.id}`,
        shipmentDraftPayloadFactory(params),
        { apiGatewayAuthorization: true, customHeaders },
        'https://api.cloud.sennder.com/shipments',
        'shipments:create'
      )) as AxiosResponse<IShipment>

      return response.data
    } catch (e) {
      logger.warn('Error creating shipment draft', e)
    }
  }

  const updateShipmentDraft = async (
    shipment: IShipment,
    params: IShipmentDraftPayloadData
  ): Promise<IShipment> => {
    try {
      const response = (await runJsonPut(
        `${SHIPMENT_API_URL}/v2/shipment-drafts/${shipment.id}`,
        updatedShipmentFactory(shipment, params),
        { apiGatewayAuthorization: true },
        'https://api.cloud.sennder.com/shipments',
        'shipments:create'
      )) as AxiosResponse<IShipment>

      return response.data
    } catch (e) {
      logger.warn('Error updating shipment draft data', e)
    }
  }

  const copyShipmentFromOrder = async (
    draftId,
    payload,
    mode = SHIPMENT_CREATION_MODE.CLONE
  ) => {
    const customHeaders = getCustomHeaders(mode)

    try {
      const response = (await runJsonPut(
        `${SHIPMENT_API_URL}/v2/shipment-drafts/${draftId}`,
        payload,
        { apiGatewayAuthorization: true, customHeaders },
        'https://api.cloud.sennder.com/shipments',
        'shipments:create'
      )) as AxiosResponse<IShipment>

      return response.data
    } catch (e) {
      logger.warn('Error copying shipment draft from order', e)
    }
  }

  const copyShipmentFromShipment = async (
    draftId,
    fromShipmentId,
    mode = SHIPMENT_CREATION_MODE.CLONE
  ) => {
    const customHeaders = getCustomHeaders(mode)

    try {
      const response = (await runJsonPut(
        `${SHIPMENT_API_URL}/v2/shipment-drafts/${draftId}/from-shipment/${fromShipmentId}`,
        null,
        { apiGatewayAuthorization: true, customHeaders },
        'https://api.cloud.sennder.com/shipments',
        'shipments:create'
      )) as AxiosResponse<IShipment>

      return response.data
    } catch (e) {
      logger.warn('Error copying shipment draft from shipment', e)
    }
  }

  const copyShipmentManagersFromOrder = async (orderId, shipmentDraftId) => {
    try {
      const saom = await getDesignatedOperators(orderId, [
        OperatorRoleId.SeniorAccountManager
      ])
      const spot = await getDesignatedOperators(orderId, [OperatorRoleId.SpotBidder])
      if (saom[0]?.operatorId) {
        await assignOperatorToShipmentDraft(shipmentDraftId, {
          operator_id: saom[0].operatorId,
          role: 'snr_aom'
        })
      }
      if (spot[0]?.operatorId) {
        await assignOperatorToShipmentDraft(shipmentDraftId, {
          operator_id: spot[0].operatorId,
          role: 'spot_bidder'
        })
      }
    } catch (err) {
      logger.warn(err)
    }
  }

  const assignOperatorToShipmentDraft = async (shipmentDraftId, payload) => {
    try {
      const response = (await runJsonPut(
        `${OPERATORS_API_URL}/v1/role/shipment/${shipmentDraftId}`,
        payload,
        { apiGatewayAuthorization: true },
        SINGLE_AUDIENCE
      )) as AxiosResponse

      return response.data
    } catch (e) {
      logger.warn('Error assigning operator to shipment draft', e)
    }
  }

  const getAssignedOperatorsOfShipmentDraft = async shipmentDraftId => {
    try {
      const response = (await runJsonGet(
        `${OPERATORS_API_URL}/v1/roles/shipment/${shipmentDraftId}`,
        null,
        {
          apiGatewayAuthorization: true
        },
        SINGLE_AUDIENCE
      )) as AxiosResponse

      return response.data
    } catch (e) {
      logger.warn('Error getting assigned operators to shipment draft', e)
    }
  }
  const cancelShipmentDraft = async (id: string) => {
    try {
      await runJsonDelete(
        `${SHIPMENT_API_URL}/v2/shipment-drafts/${id}`,
        null,
        { apiGatewayAuthorization: true },
        'https://api.cloud.sennder.com/shipments',
        'shipments:create'
      )

      router.replace(`/`)

      return
    } catch (e) {
      logger.warn('Cancel Draft action failed!', e)
    }
  }

  const fetchWarehouseDetailsById = async id => {
    const response = (await runJsonGet(
      `${OPERATIONS_BACKEND_URL}/ordering-customer/queries/get-address-details/${id}`
    )) as AxiosResponse
    return response.data
  }

  const constructShipmentPayload = (order, stops, draftId) => {
    const shipmentPayload = {
      financials: {
        surcharges: [],
        note: '',
        base_price: {
          number: order.basePrice,
          currency: 'EUR'
        }
      },
      shipper: {
        shipper_id: order.customerId,
        contact_id: order.customerContact.id,
        contract: { type: order.regularity }
      },
      loads: [],
      shipment_meta: {},
      reference: order.referenceNumber,
      shipment_requirements: {
        arrival_notification: order.arrivalNotification,
        direct_delivery: order.directTransfer
      },
      id: draftId
    }

    const constructRequirements = (windowOpenAt, windowCloseAt, stop) => {
      return {
        id: crypto.randomUUID(),
        [windowOpenAt]: moment
          .tz(
            `${stop.startDate} ${stop.startTime}`,
            'DD.MM.YYYY HH:mm',
            stop.warehouseAddress.timezone
          )
          .utc()
          .format(),
        [windowCloseAt]: moment
          .tz(
            `${stop.endDate} ${stop.endTime}`,
            'DD.MM.YYYY HH:mm',
            stop.warehouseAddress.timezone
          )
          .utc()
          .format(),
        warehouse_address_id: stop.warehouseAddress.companyAddressId,
        warehouse_contact_id: stop.contactPersonId,
        reference: stop.referenceNumber,
        note: stop.notes
      }
    }

    const loadingReqs = stops
      .filter(el => el.stopoverType === 'LOADING')
      .map(loadingStop =>
        constructRequirements(
          'loading_window_open_at',
          'loading_window_closed_at',
          loadingStop
        )
      )
    const unloadingReqs = stops
      .filter(el => el.stopoverType === 'UNLOADING')
      .map(unloadingStop =>
        constructRequirements(
          'unloading_window_open_at',
          'unloading_window_closed_at',
          unloadingStop
        )
      )

    const newLoad = {
      quantity: order.loadQuantity,
      // tons to kg
      total_weight_in_kg:
        order.loadWeight === null ? order.loadWeight : order.loadWeight * 1000,
      load_unit: {
        type: order.loadUnitType,
        description: order.loadDescription,
        // cm to m
        height_in_meters:
          order.loadHeight === null ? order.loadHeight : order.loadHeight / 100,
        length_in_meters:
          order.loadLength === null ? order.loadLength : order.loadLength / 100,
        width_in_meters:
          order.loadWidth === null ? order.loadWidth : order.loadWidth / 100
      },
      load_requirements: {
        temperature_requirement: {
          minimum_temperature: order.loadMinimumTemperature,
          maximum_temperature: order.loadMaximumTemperature
        },
        pallet_exchange_requirement: {
          is_required: order.needsPalletExchange
        },
        sealed_requirement: { is_required: order.sealable },
        warehouse_loading_requirement: {
          top_loading: order.topLoading,
          side_loading: order.sideLoading,
          dock_loading: order.dockLoading
        },
        vehicle_requirement: {
          preferred_vehicle_type: order.vehicleType,
          acceptable_replacement_vehicle_types: order.allowedVehicleTypes,
          code_xl: order.codeXl,
          cleaned: false
        },
        certification_requirement: {
          food_certified: false,
          waste_certified: false,
          dangerous_goods_certified: false
        },
        driver_requirement: { has_safety_vest: false },
        tracking_requirement: {
          tracking_id_for_shipper:
            order.trackingIdForShipper === null ? undefined : order.trackingIdForShipper,
          is_required: false
        },
        loading_requirements: loadingReqs,
        unloading_requirements: unloadingReqs
      }
    }

    const newLoadCopy = omit(cloneDeep(newLoad), [
      'load_requirements.loading_requirements[0].id',
      'load_requirements.unloading_requirements[0].id'
    ])

    if (
      !newLoadCopy.load_requirements.vehicle_requirement?.preferred_vehicle_type &&
      newLoadCopy.load_requirements.vehicle_requirement
        ?.acceptable_replacement_vehicle_types?.length
    ) {
      const [firstReplacementType, ...remainingReplacementTypes] =
        newLoadCopy.load_requirements.vehicle_requirement
          .acceptable_replacement_vehicle_types

      newLoadCopy.load_requirements.vehicle_requirement.preferred_vehicle_type =
        firstReplacementType
      newLoadCopy.load_requirements.vehicle_requirement.acceptable_replacement_vehicle_types =
        remainingReplacementTypes
    }

    shipmentPayload.loads = [newLoadCopy]
    return shipmentPayload
  }

  const generateShipmentEditRequestPayload = formData => {
    const requirementUpdates = formData?.stops.map(stop => {
      const windowOpenAt = createUtcByWarehouseTimezone(
        stop.startDate,
        stop.startTime,
        stop.warehouseAddress.timezone
      )
      const windowClosedAt = createUtcByWarehouseTimezone(
        stop.endDate,
        stop.endTime,
        stop.warehouseAddress.timezone
      )

      const update = {
        id: stop.id,
        reference: stop.referenceNumber,
        type: stop.type,
        loading_window_open_at: windowOpenAt,
        loading_window_closed_at: windowClosedAt,
        unloading_window_open_at: windowOpenAt,
        unloading_window_closed_at: windowClosedAt
      }

      if (update.type === 'LOADING') {
        delete update.unloading_window_open_at
        delete update.unloading_window_closed_at
      } else {
        delete update.loading_window_open_at
        delete update.loading_window_closed_at
      }

      return update
    })

    const shipmentCancellation = formData.isShipmentCanceled
      ? {
          shipment_cancellation: {
            reason_id: formData.reason,
            comment: formData.comment,
            user_id: formData.userId,
            canceled: !!formData.isShipmentCanceled
          }
        }
      : {}

    const payload = {
      shipment_update: {
        reference: formData.shipment?.referenceNumber
      },
      ...shipmentCancellation,
      loading_requirement_updates: requirementUpdates.filter(
        update => update.type === 'LOADING'
      ),
      unloading_requirement_updates: requirementUpdates.filter(
        update => update.type === 'UNLOADING'
      ),
      vehicle_requirement_update: {
        preferred_vehicle_type: formData.preferredVehicleType,
        acceptable_replacement_vehicle_types: formData.acceptableReplacementVehicleTypes
      },
      tracking_requirement_update: {
        tracking_id_for_shipper: formData.shipment?.trackingIdForShipper
      }
    }

    return payload
  }

  return {
    shipment,
    generateShipmentEditRequestPayload,
    customer,
    contact,
    shipmentRoute,
    subContractingPolicy,
    shipperProfileaccountStatus,
    loadShipmentData,
    isLoadShipmentDataInProgress,
    createShipmentDraft,
    updateShipmentDraft,
    copyShipmentFromOrder,
    copyShipmentFromShipment,
    cancelShipmentDraft,
    constructShipmentPayload,
    error,
    createShipmentEditRequest,
    getShipmentIdByOrderId,
    copyShipmentManagersFromOrder,
    getAssignedOperatorsOfShipmentDraft,
    assignOperatorToShipmentDraft,
    isShipmentEditRequestSuccessful,
    shipmentEditRequestId,
    fetchWarehouseDetailsById
  }
}
