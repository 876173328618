import Vue from 'vue'
import {
  IOctopusSharedData,
  SharedDataType
} from '@sennder/senn-node-microfrontend-interfaces'
import { permissions } from '../data/user'

interface SharedDataStore {
  sharedData: IOctopusSharedData
}

const store: SharedDataStore = Vue.observable({
  sharedData: {
    type: SharedDataType.OCTOPUS,
    env: {},
    featureFlags: {
      // 'ENABLE_FACILITY-PLANNING-UI-BY-SHIPPER': {
      //   onboarded_shippers_id: [100689, 101103]
      // },
      // ENABLE_FACILITY_MANUAL_SCHEDULING_NW: {
      //   env: 'local',
      //   onboarded_shippers_id: [101103]
      // },
      // ENABLE_FACILITY_SCHEDULING_TAB_READ_ONLY: {
      //   env: 'local',
      //   onboarded_shippers_id: [101103]
      // },
      'ENABLE_AUTH0-AUTO-LOGOUT': true,
      'ENABLE_BIDDING-ASYNC-FLOW': true,
      'ENABLE_CARRIER-ONBOARDING-MERGED-TENANTS': true,
      ENABLE_CARRIER_PROFILE_PAGE: true,
      ENABLE_CARRIER_SEARCH_PAGE: true,
      ENABLE_CLAIM_ACCESSORIAL: true,
      'ENABLE_CLONE-SHIPMENT-ENDPOINT': false,
      'ENABLE_CONVERTED-COST-TOOLTIP': true,
      ENABLE_COUNTEROFFER: true,
      'ENABLE_DIRECT-DISPATCH': true,
      'ENABLE_FACILITY-MANAGEMENT-PAGE': true,
      'ENABLE_FACILITY-MANUAL-SCHEDULING': false,
      'ENABLE_FACILITY-PLANNING-UI': true,
      'ENABLE_FACILITY-RESCHEDULING-FLOW': true,
      'ENABLE_FACILITY-SEARCH': true,
      ENABLE_FACILITY_MULTI_SEARCH: true,
      ENABLE_FACILITY_SEARCH_BY_LOCATION: true,
      'ENABLE_LOOKER_DASHBOARD-SPOTTER': false,
      'ENABLE_MANUAL-SCHEDULING-UI': true,
      'ENABLE_NEW-SHIPMENT-DRAFT': false,
      ENABLE_NEW_GLOBAL_SEARCH_PAGE: false,
      ENABLE_NEW_POD_REVIEW_WIDGET: true,
      ENABLE_NEW_POD_URLS: true,
      'ENABLE_OCTOPUS-INTERCOMM': true,
      'ENABLE_OCTOPUS-PAYMENTS-WIDGET': true,
      'ENABLE_OCTOPUS-SENNLAB': true,
      'ENABLE_ONE-VIEW-BOARD__GROUPS--TRANSIT': true,
      'ENABLE_ONE-VIEW-MONO-REPO': false,
      'ENABLE_SPOTTER-LINK-WIDGET': true,
      ENABLE_ONE_VIEW_BULK_EVENTS: true,
      ENABLE_ONE_VIEW_EXAMPLES_COMPONENTS: true,
      ENABLE_SHIPMENT_DRAFT_CONTRACT_MATCHING: false,
      ENABLE_SPOTTER_PAGE: true,
      '82731_ALLOCATE_ASSET_FROM_TP_ID': false,
      '87236-disable-soft-delete': false,
      'ENABLE_SUBMIT-OFFER-FOR-DOMESTIC-CARRIERS-PAID-IN-LOCAL-CURRENCY': true,
      'PLAN-1416-fleet-vehicle-allocation': false,
      'PLAN-1588-use-ca-confirmation-email-service': false,
      'PLAN-1992-enable-asset-delete': true,
      'PLAN-2082-enable-autosave': false,
      'REDIRECT_DISPATCHING-BOARD': false,
      'REDIRECT_REQUEST-BOARD': true,
      'SHOW_MANUAL-SUGGESTED-BASE-COST': false,
      'SHOW_MY-CARRIERS-PAGE': false,
      Testing_Payment: false,
      bsb_1844_notification_center_fix: true,
      'enable-cm-referral-link': true,
      'enable-manual-assignment-flow': false,
      'enable-new-es-execution-board': false,
      'enable-new-es-request-board': true,
      'execution-mandatory-timestamps': false,
      'mothership-login': false,
      'new-notification-center-old-api': true,
      'notification-center': true,
      'pod-upload-use-linked-carrier-id': true,
      'remove-tenant-restriction': false,
      'shipment-executed': true,
      'show-manual-eta': false,
      'show-shipping-page': false,
      'spotter-looker-integration': false,
      'transit-facility-actions': false,
      'use-shipment-incident-mgmt': false,
      'ENABLE_NEW-V3-ORDER-HEADER': true,
      ENABLE_PALLET_EXCHANGE_FORM: false,
      '97605-use-fleet-v3-endpoint': false
    },
    language: 'en',
    tenant: 'sennder',
    user: {
      id: 20075,
      uuid: 'test-uuid',
      username: 'foobar@sennder.com',
      email: '',
      fullNameWithoutTitle: 'Foo Bar',
      firstName: 'Foo',
      lastName: 'Bar',
      language: 'en',
      uiLanguage: 'en',
      timezone: 'Europe/Berlin',
      inStaffGroup: true,
      inFinanceGroup: true,
      inCustomerGroup: false,
      firstLogin: false,
      paymentTermPopUpDaysPast: 1,
      cookies: [],
      groupNames: ['PartnerManager', 'Staff', 'AccountManager', 'Finance'],
      permissions,
      featureFlags: [],
      positionTitle: 'Title'
    }
  } as IOctopusSharedData
})

export const setSharedData = (data: IOctopusSharedData) => {
  store.sharedData = data
}

const sharedData = (): IOctopusSharedData => {
  return store.sharedData
}

export default sharedData
