




























import { defineComponent, PropType, computed } from '@vue/composition-api'
import startCase from 'lodash/startCase'
import LabeledText from '@/modules/common/components/LabeledText.vue'

export default defineComponent({
  components: { LabeledText },
  props: {
    specifications: {
      type: Array as PropType<
        { label: string; value: string | number; measuringUnitLabel: string }[]
      >,
      required: true
    },
    loadDescription: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const filteredSpecifications = computed(() =>
      props.specifications.filter(spec => !!spec.value)
    )

    return { filteredSpecifications, startCase }
  }
})
