import { monitorError } from '@/analytics/monitoring'
import { getTourExecution } from '@/api/services/transit.service'
import { TourExecution } from '@/types/transit'
import { computed, ref } from '@vue/composition-api'
import { AxiosError } from 'axios'
import { defineStore } from 'pinia'

export const useTransitStore = defineStore('transit-store', () => {
  // state
  const loaders = ref<string[]>([])
  const tourExecution = ref<TourExecution | null>(null)

  // getters
  const isLoading = computed<boolean>(() => loaders.value.length > 0)

  // actions
  async function fetchTourExecution(shipment_id: string, showLoader = true) {
    try {
      if (showLoader) loaders.value.push('tourExecution')
      tourExecution.value = await getTourExecution(shipment_id)
    } catch (err: unknown) {
      // execution may not exist for the shipment, this is not an error
      if ((err as AxiosError).response?.status === 404) return

      monitorError(new Error('Failed to fetch tour execution'), 'octopus-new-store', {
        err
      })

      throw err
    } finally {
      loaders.value = loaders.value.filter(loader => loader !== 'tourExecution')
    }
  }

  return {
    loaders,
    isLoading,
    tourExecution,
    fetchTourExecution
  }
})
