import { monitorError } from '@/analytics/monitoring'
import { getCarrier, getCarrierContact } from '@/api/services/carrier-profile.service'
import { Carrier, Contact } from '@sennder/carrier-profile-axios-client'
import { ref, computed } from '@vue/composition-api'
import { defineStore } from 'pinia'

export const useCarrierStore = defineStore('carrier-store', () => {
  // state
  const loaders = ref<string[]>([])
  const carrier = ref<Carrier | null>(null)
  const contact = ref<Contact | null>(null)

  // getters
  const isLoading = computed<boolean>(() => loaders.value.length > 0)

  // actions
  async function fetchCarrier(carrierId: string, showLoader = true) {
    try {
      if (showLoader) loaders.value.push('carrier')
      const fetchedCarrier = await getCarrier(carrierId)
      carrier.value = fetchedCarrier
    } catch (err) {
      monitorError(new Error('Failed to fetch carrier'), 'octopus-new-store', {
        err
      })

      throw err
    } finally {
      loaders.value = loaders.value.filter(loader => loader !== 'carrier')
    }
  }

  async function fetchCarrierContact(contactId: string, showLoader = true) {
    try {
      if (showLoader) loaders.value.push('carrier')
      const fetchedContact = await getCarrierContact(contactId)
      contact.value = fetchedContact
    } catch (err) {
      monitorError(new Error('Failed to fetch carrier contact'), 'octopus-new-store', {
        err
      })

      throw err
    } finally {
      loaders.value = loaders.value.filter(loader => loader !== 'carrier')
    }
  }

  return {
    loaders,
    isLoading,
    carrier,
    contact,
    fetchCarrier,
    fetchCarrierContact
  }
})
