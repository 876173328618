import { monitorError } from '@/analytics/monitoring'
import {
  getOperator,
  getOperatorsForShipment,
  getOperatorsForTransportOffer
} from '@/api/services/operators.service'
import { Operator, OperatorAssignment } from '@/types/operators'
import { computed, ref } from '@vue/composition-api'
import { defineStore } from 'pinia'

export const useOperatorsStore = defineStore('operators-store', () => {
  // state
  const loaders = ref<string[]>([])
  const assignments = ref<OperatorAssignment[]>([])
  const operators = ref<Operator[]>([])

  // getters
  const isLoading = computed<boolean>(() => loaders.value.length > 0)

  // actions
  async function fetchOperatorAssignments(
    { shipmentId, transportOfferId }: { shipmentId?: string; transportOfferId?: string },
    showLoader = true
  ) {
    if (showLoader) loaders.value.push('operators')

    return Promise.all([
      shipmentId ? getOperatorsForShipment(shipmentId) : [],
      transportOfferId ? getOperatorsForTransportOffer(transportOfferId) : []
    ])
      .then(([shipmentOperators, transportOfferOperators]) => {
        assignments.value = [...shipmentOperators, ...transportOfferOperators]
      })
      .catch(err => {
        monitorError(
          new Error('Failed to fetch operator assignments'),
          'octopus-new-store',
          {
            err
          }
        )
        throw err
      })
      .finally(() => {
        loaders.value = loaders.value.filter(loader => loader !== 'operators')
      })
  }

  async function fetchOperators(operatorIds: string[], showLoader = true) {
    if (showLoader) loaders.value.push('operators')

    return Promise.all(operatorIds.map(operatorId => getOperator(operatorId)))
      .then(fetchedOperators => {
        operators.value = fetchedOperators
      })
      .catch(err => {
        monitorError(new Error('Failed to fetch operators'), 'octopus-new-store', {
          err
        })
        throw err
      })
      .finally(() => {
        loaders.value = loaders.value.filter(loader => loader !== 'operators')
      })
  }

  async function fetchAssignmentsWithOperators(
    { shipmentId, transportOfferId }: { shipmentId?: string; transportOfferId?: string },
    showLoader = true
  ) {
    try {
      await fetchOperatorAssignments({ shipmentId, transportOfferId }, showLoader)

      // no assignments, no need to fetch operators
      if (assignments.value.length === 0) return

      await fetchOperators(
        assignments.value.map(assignment => assignment.operator_id),
        showLoader
      )
    } catch (err) {
      monitorError(
        new Error('Failed to fetch assignments with operators'),
        'octopus-new-store',
        {
          err
        }
      )
      throw err
    }
  }

  return {
    loaders,
    isLoading,
    assignments,
    operators,
    fetchOperatorAssignments,
    fetchOperators,
    fetchAssignmentsWithOperators
  }
})
