import { monitorError } from '@/analytics/monitoring'
import { getCarrierCost, getShipperPrice } from '@/api/services/settlement.service'
import { CarrierCost, ShipperPrice } from '@/types/settlement'
import { computed, ref } from '@vue/composition-api'
import { AxiosError } from 'axios'
import { defineStore } from 'pinia'

export const useSettlementStore = defineStore('settlement-store', () => {
  // state
  const loaders = ref<string[]>([])
  const carrierCost = ref<CarrierCost | null>(null)
  const shipperPrice = ref<ShipperPrice | null>(null)

  // getters
  const isLoading = computed<boolean>(() => loaders.value.length > 0)

  // actions
  async function fetchCarrierCost(external_id: string, showLoader = true) {
    try {
      if (showLoader) loaders.value.push('carrierCost')
      carrierCost.value = await getCarrierCost(external_id)
    } catch (err: unknown) {
      // carrier cost may not exist for the shipment, this is not an error
      if ((err as AxiosError).response?.status === 404) return

      monitorError(new Error('Failed to fetch carrier cost'), 'octopus-new-store', {
        err
      })

      throw err
    } finally {
      loaders.value = loaders.value.filter(loader => loader !== 'carrierCost')
    }
  }

  async function fetchShipperPrice(external_id: string, showLoader = true) {
    try {
      if (showLoader) loaders.value.push('shipperPrice')
      shipperPrice.value = await getShipperPrice(external_id)
    } catch (err: unknown) {
      // shipper price may not exist for the shipment, this is not an error
      if ((err as AxiosError).response?.status === 404) return

      monitorError(new Error('Failed to fetch shipper price'), 'octopus-new-store', {
        err
      })

      throw err
    } finally {
      loaders.value = loaders.value.filter(loader => loader !== 'shipperPrice')
    }
  }

  return {
    loaders,
    isLoading,
    carrierCost,
    shipperPrice,
    fetchCarrierCost,
    fetchShipperPrice
  }
})
