export enum MATCH_STATE {
  IN_PROGRESS = 'IN_PROGRESS',
  CHARTER_MATCHED = 'CHARTER_MATCHED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED'
}

interface FinancialAmount {
  number: number
  currency: string
}

interface Surcharge {
  id: string
  tenant: string
  type: string
  amount: FinancialAmount
  description: string
}

interface Financials {
  cost: FinancialAmount
  surcharges: Surcharge[]
  note: string
}

export interface TransportOffer {
  pk?: string
  id: string
  tenant: string
  created: string
  modified: string
  version: number
  shipment_ids: string[]
  order_ids: number[]
  transportoffer_route: string
  financial?: string
  loading_requirements: string
  facilities: string[]
  loads: string[]
  active: boolean
  external_id?: string
  is_international?: boolean
  country_codes?: string[]
  transaction_id: string
}

export interface Match {
  id: string
  tenant: string
  created: string
  modified: string
  match_source: string
  matching_flow: string
  shipment_ids: string[]
  order_ids: number[]
  matching_entity_id?: string
  state: MATCH_STATE
  creator_id: string
  creator_role: string
  carrier_id?: string
  carrier_contact_id?: string
  chartering_office_id?: string
  transportoffer: {
    pk: string
    id: string
    version: number
  }
  financials: Financials
  sennder_subsidiary_id: number
  active: boolean
  transaction_id: string
}

export interface TransportOfferWithMatches {
  transport_offer: TransportOffer
  matches: Match[]
}
