import { TRANSPORT_PLANNING_API_URL } from '@/config'
import { TransportPlanDetails } from '@/types/transport-planning'
import { transportPlanningClient } from '../clients/transport-planning.client'

export async function getTransportPlanDetails(externalId: string) {
  const response = await transportPlanningClient.get<TransportPlanDetails>(
    `${TRANSPORT_PLANNING_API_URL}/api/octopus/v1/transport-plan-details/${externalId}`
  )

  return response.data
}
