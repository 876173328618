import { SETTLEMENT_API_URL } from '@/config'
import { genericClient } from '../clients/generic.client'
import { CarrierCost, ShipperPrice } from '@/types/settlement'

export async function getShipperPrice(external_id: string): Promise<ShipperPrice> {
  const response = await genericClient.get<ShipperPrice>(
    `${SETTLEMENT_API_URL}/api/shipper-price/v1/order/${external_id}`
  )
  return response.data
}

export async function getCarrierCost(external_id: string): Promise<CarrierCost> {
  const response = await genericClient.get<CarrierCost>(
    `${SETTLEMENT_API_URL}/api/carrier-cost/v1/order/${external_id}`
  )
  return response.data
}
