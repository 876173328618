export enum ASSET_TYPE {
  TRUCK = 'TRUCK',
  TRAILER = 'TRAILER',
  DRIVER = 'DRIVER'
}

export enum TRANSPORT_PLAN_STATE {
  DRAFT = 'DRAFT',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED'
}

interface Allocation {
  id: string
  asset_id: string
  asset_type: ASSET_TYPE
}

export interface TransportPlanDetails {
  meta: {
    created: string
  }
  data: {
    transport_plan_id: string
    external_id: string
    shipment_id: string
    transport_offer_id?: string
    workflow_managed: boolean
    is_charter_load: boolean
    transport_plan_state: string
    transport_plan_created_at: string
    allocations: Allocation[]
  }
}
