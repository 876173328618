var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"octopus-payments-widget-portal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var externalId = ref.externalId;
var mode = ref.mode;
return [_c('OctopusPaymentsWidget',{attrs:{"external-id":externalId,"mode":mode}})]}}])}),_c('portal',{attrs:{"to":"pod-review-widget"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var orderId = ref.orderId;
var includeTracking = ref.includeTracking;
var disabled = ref.disabled;
var externalId = ref.externalId;
var shipmentId = ref.shipmentId;
return [_c('OctopusPODReviewWidget',{attrs:{"orderId":orderId,"external-id":externalId,"shipment-id":shipmentId,"includeTracking":includeTracking,"disabled":disabled}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }