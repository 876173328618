import { SHIPPER_PROFILE_API_URL } from '@/config'
import { shipperProfileClient } from '../clients/shipper-profile.client'
import { Shipper } from '@/types/shipper'

export async function getShipper(shipperId: string): Promise<Shipper> {
  const response = await shipperProfileClient.get<Shipper>(
    `${SHIPPER_PROFILE_API_URL}/api/shippers/${shipperId}`
  )

  return response.data
}
