import { AxiosResponse } from 'axios'
import { runJsonGet } from '@/models/backend-client'
import { FACILITY_PROFILE_API_URL, USER_MANAGEMENT_API_AUDIENCE } from '@/config'
import { IFacilityProfile } from '@/modules/shipment/types/shipment'
const FACILITY_PROFILE_API_SCOPE = 'profiles:read'

export const fetchFacilityProfile = async (
  facilityProfileId: string
): Promise<IFacilityProfile | null> => {
  const url = `${FACILITY_PROFILE_API_URL}/v2/profiles/${facilityProfileId}`
  const customHeaders = { 'x-client-section': 'com.sennder.ingestion' }

  const response = (await runJsonGet(
    url,
    null,
    {
      apiGatewayAuthorization: true,
      customHeaders
    },
    USER_MANAGEMENT_API_AUDIENCE,
    FACILITY_PROFILE_API_SCOPE
  )) as AxiosResponse<IFacilityProfile>

  return response ? response.data : null
}
