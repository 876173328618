export const CAN_VIEW_TEAM_PAGE = 'user_management.can_view_team_page'

export const CAN_RECEIVE_NOTIFICATIONS = 'notification_center.can_receive_notifications'

export const CAN_WITHDRAW_ANY_CARRIER_OFFER = 'orders_matching.can_withdraw_any_pm_offer'

export const CAN_COPY_ORDER = 'ordering.can_copy_order'

export const CAN_ADD_CARRIER_SURCHARGES = 'ordering.can_add_carrier_surcharges'

export const CAN_ADD_CUSTOMER_SURCHARGES = 'ordering.can_add_customer_surcharges'

export const CAN_EDIT_VARIABLE_ORDERS = 'ordering.can_edit_variable_orders'

export const CAN_VIEW_CONTRACT_MANAGEMENT = 'contract_management.can_view_contract'
export const CAN_VIEW_CONTRACT_MANAGEMENT_AUTH0 =
  'https://api.cloud.sennder.com/contractmanagement:contracts:read-any'

export const CAN_VIEW_COST_CENTER_AUTH0 =
  'https://api.cloud.sennder.com/cost-center-mgmt:cost-center:read'

export const CAN_EDIT_PICKUP_DATE_WITHOUT_LIMITATIONS =
  'ordering.can_edit_pickup_date_without_limitations'
