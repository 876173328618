import OrderDetails from '@/modules/ordering/components/OrderDetails.vue'
import { Route } from 'vue-router'

export default [
  {
    name: 'ordering',
    path: 'ordering/:orderGroupId(\\d+):letter([A-Z]+)',
    component: OrderDetails
  },
  {
    // Definition of old order URL. Need it in case of emails that still point to this URL.
    name: 'old_order_url',
    path: '/order/:orderGroupId/*',
    redirect: (route: Route) => {
      const orderGroupId = route.params.orderGroupId
      const letter = route.query.letter || 'A'
      return { name: 'ordering', params: { orderGroupId, letter }, query: null }
    }
  }
]
