import axios, { AxiosError } from 'axios'
import { getAuth } from '../../../core-config/orchestrated/auth'

const transportPlanningClient = axios.create()

transportPlanningClient.interceptors.request.use(
  async function (config) {
    const authorization = await getAuth().getAuthHeader({
      audience: 'https://api.cloud.sennder.com/transport-planning',
      scope: 'transport-allocations:read-all transport-plans:read'
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers = {
      ...config.headers,
      Authorization: authorization
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

transportPlanningClient.interceptors.response.use(
  function (response) {
    return response
  },
  function (error: AxiosError) {
    return Promise.reject(error)
  }
)

export { transportPlanningClient }
