




















import { computed, defineComponent, PropType } from '@vue/composition-api'
import { LoadDetails, CarrierSearchLookerDashboard } from './CarrierSearchLookerDashboard'

export default defineComponent({
  components: {},
  props: {
    loadDetails: { type: Object as PropType<LoadDetails>, required: true }
  },
  setup(props) {
    const lookerDashboardUrl = computed(() => {
      return new CarrierSearchLookerDashboard(props.loadDetails).getEmbedUrl()
    })

    return {
      show: false,
      lookerDashboardUrl
    }
  },
  methods: {
    async open() {
      this.show = true
    },
    close() {
      this.show = false
    }
  }
})
