import { OPERATORS_API_URL, USER_MANAGEMENT_API_URL } from '@/config'
import { genericClient } from '../clients/generic.client'
import { Operator, OperatorAssignment } from '@/types/operators'

export async function getOperatorsForShipment(
  shipmentId: string
): Promise<OperatorAssignment[]> {
  const response = await genericClient.get<OperatorAssignment[]>(
    `${OPERATORS_API_URL}/v1/roles/shipment/${shipmentId}`
  )

  return response.data
}

export async function getOperatorsForTransportOffer(
  shipmentId: string
): Promise<OperatorAssignment[]> {
  const response = await genericClient.get<OperatorAssignment[]>(
    `${OPERATORS_API_URL}/v1/roles/transport_offer/${shipmentId}`
  )

  return response.data
}

export async function getOperator(operatorId: string): Promise<Operator> {
  const response = await genericClient.get<Operator>(
    `${USER_MANAGEMENT_API_URL}/api/users/${operatorId}`
  )

  return response.data
}
