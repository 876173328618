import { Currency } from '@/modules/common'
import { OrderState } from '@/modules/common/order-states'
import { StepType, StopoverType } from '@/services/enums'
import { Timestamp } from '@sennder/plankton'

export type UUID = string

export type VehicleTypeV3 =
  | 'van'
  | 'truck_3_5'
  | 'truck_7_5'
  | 'truck_12'
  | 'truck_40'
  | 'truck_40_tautliner'
  | 'truck_40_box'
  | 'truck_40_drop'
  | 'truck_40_frigo'
  | 'truck_40_mega'
  | 'truck_40_open'
  | 'truck_40_jumbo'

export const VEHICLE_TYPE_MAP = {
  VAN: 'Van',
  TRUCK_3_5: '3.5t Truck',
  TRUCK_7_5: '7.5t Truck',
  TRUCK_12: '12t Truck',
  TRUCK_40: '40t Truck',
  TRUCK_40_TAUTLINER: '40t Tautliner',
  TRUCK_40_BOX: '40t Box',
  TRUCK_40_DROP: '40t Drop',
  TRUCK_40_FRIGO: '40t Frigo',
  TRUCK_40_MEGA: '40t Mega',
  TRUCK_40_OPEN: '40t Open',
  TRUCK_40_JUMBO: '40t Jumbo'
} as const

export type AllowedVehicleType = keyof typeof VEHICLE_TYPE_MAP

export interface AllowedVehicles {
  [key: string]: boolean
}

export enum MothershipOperatorRole {
  SeniorAccountManager = 'SENIOR_ACCOUNT_MANAGER',
  AccountManager = 'ACCOUNT_MANAGER',
  SpotBidder = 'SPOT_BIDDER'
}

export interface OrderCreation {
  referenceNumber?: number
  customerCompanyId: number
  customerContactId: number
  trackingIdForShipper?: string
}

export interface OrderCreationResponse {
  orderId: number
  idForStaff: string
}

export interface OrderTeamLeads {
  id: number
  email: string
  firstName: string
  lastName: string
  teamName: string
}
export interface PMTeam {
  id: number
  name: string
  officeId: number
}

export type ISODateString = string

export type Timezone = string

export interface OrderStopFetch {
  id: number
  orderId: number
  index: number
  warehouseAddressId: number
  warehouseAddress: CustomerAddress
  warehouseIndex?: number
  customerCompanyId: number
  customerCompany: Company
  contactPersonId?: number | null
  contactPerson?: ContactBase
  stopoverType: StopoverType
  arrivalToWarehouse: ISODateString
  departureFromWarehouse: ISODateString
  referenceNumber?: string
  notes?: string | null
}

export interface OrderStopToEdit
  extends Omit<OrderStopFetch, 'warehouseAddress' | 'customerCompany' | 'contactPerson'> {
  companyId: number
}

export interface OrderStop extends OrderStopFetch {
  arrivalToWarehouse: Timestamp
  departureFromWarehouse: Timestamp
  startDate: string
  endDate: string
  startTime: string
  endTime: string
  contactPerson?: CustomerContact
  isOvernightLoad: boolean
}

export interface OrderStopToCreate
  extends Omit<
    OrderStop,
    'id' | 'customerCompanyId' | 'index' | 'arrivalToWarehouse' | 'departureFromWarehouse'
  > {
  id: number | null
  localId: string
}
export interface OrderCustomerUpdate extends OrderCreation {
  orderId: number
}

export type VehicleType =
  | 'VAN'
  | 'TRUCK_3_5'
  | 'TRUCK_7_5'
  | 'TRUCK_12'
  | 'TRUCK_40'
  | 'TRUCK_40_TAUTLINER'
  | 'TRUCK_40_BOX'
  | 'TRUCK_40_DROP'
  | 'TRUCK_40_FRIGO'
  | 'TRUCK_40_MEGA'
  | 'TRUCK_40_OPEN'
  | 'TRUCK_40_JUMBO'

export type LoadUnitType =
  | 'Europallets'
  | 'Industrial_pallets'
  | 'Colli'
  | 'Cage_Pallets'
  | 'Parcels'
  | 'Duesseldorfer_pallets'
  | 'Chep_pallets'

export interface ContactBase {
  id: number
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
}

export interface OrderOperator {
  id: number
  firstName: string
  lastName: string
  email: string
  fullName?: string
}

export interface CustomerContact extends ContactBase {
  name: string
}

export type CarrierContact = ContactBase

export interface CarrierKpi {
  carrierId: number
  isTrackable: boolean
  onTimeDeliveryPercentage: number
  onTimePickupPercentage: number
  totalOnTimeDelivery: number
  totalOnTimePickup: number
  totalOrders: number
  totalOrdersSameLane: number
  totalOrdersWithOnTimeData: number
  totalOrdersWithSameCustomer: number
}

export declare interface CarrierTrackingRate {
  rate: number
}

export declare interface CustomerTemplate {
  destinationCompany: string
  destinationCompanyAddress: string
  originCompany: string
  originCompanyAddress: string
  lineCode: string
}
export interface OrderTemplate {
  accountManagerId?: number
  arrivalNotification?: boolean
  autofillLoadingAndUnloadingReference?: boolean
  baseCost?: number
  basePrice?: number
  carrierCompanyId?: number
  carrierContactId?: number
  codeXl?: boolean
  customerCompany?: string
  customerCompanyAddressId?: number
  customerCompanyId?: number
  customerContactId?: number
  destinationCompany?: string
  destinationCompanyAddress?: string
  destinationCompanyAddressId?: number
  destinationCompanyId?: number
  destinationContact?: string
  destinationContactId?: number
  directTransfer?: boolean
  dockLoading?: boolean
  isTemperatureControlled?: boolean
  lineCode?: string
  loadDescription?: string
  loadHeight?: number
  loadLength?: number
  loadMaximumTemperature?: number
  loadMinimumTemperature?: number
  loadQuantity?: number
  loadUnitType?: string
  loadWeight?: number
  loadWidth?: number
  loadingNotes?: string
  loadingReference?: string
  needsPalletExchange?: boolean
  originCompany?: string
  originCompanyAddress?: string
  originCompanyAddressId?: number
  originCompanyId?: number
  originContact?: string
  originContactId?: number
  referenceNumber?: string
  regularity?: string
  sealable?: boolean
  sideLoading?: boolean
  topLoading?: boolean
  trackingIdForShipper?: number
  unloadingNotes?: string
  unloadingReference?: string
  vehicleType?: VehicleType
}

export interface CustomerAddress {
  id: number
  fullAddress: string
  companyAddressId: number
  zipCode?: string
  timezone?: string
  city?: string
  latitude?: string
  longitude?: string
  countryCode?: string
}
export interface Company {
  id: number
  name: string
}

export interface CarrierCompany extends Company {
  gatehouseCarrierId?: number
  hasOrcasAccess?: boolean
}

export interface OrderDetail {
  accountManagerId?: number
  juniorAccountManagerId?: number
  activePartnerManagersIds?: number[]
  allowedVehicleTypes?: VehicleType[]
  arrivalNotification?: boolean
  baseCost?: number
  basePrice?: number
  carrierCompany?: CarrierCompany
  carrierContact?: CarrierContact
  codeXl?: boolean
  confirmationDocumentId?: number
  customerBillingAddress?: CustomerAddress
  customerCompanyAddress?: CustomerAddress
  customerContact?: CustomerContact
  customerId?: number
  customerName?: string
  directTransfer?: boolean
  dockLoading?: boolean
  editableForVariations?: boolean
  id: number
  idForStaff?: string
  isCancelled?: boolean
  isTemperatureControlled?: boolean
  licensePlate?: string
  lineCode?: string
  loadDescription?: string
  loadHeight?: number
  loadLength?: number
  loadMaximumTemperature?: number
  loadMinimumTemperature?: number
  loadQuantity?: number
  loadType?: unknown
  loadUnitType?: LoadUnitType
  loadWeight?: number
  loadWidth?: number
  marginPercentage?: number
  needsPalletExchange?: boolean
  orderGroupId?: number
  paletExchange?: string
  partnerManagerId?: number
  preDispatchCarrierExtraChargeAmount?: number
  preDispatchCarrierExtraChargeDescription?: string
  preDispatchCarrierExtraChargeId?: number
  preSaleCustomerExtraChargeAmount?: number
  preSaleCustomerExtraChargeDescription?: string
  preSaleCustomerExtraChargeId?: number
  pickUpDate?: string
  referenceNumber?: string
  regularity?: string
  sealable?: boolean
  sideLoading?: boolean
  state?: OrderState
  topLoading?: boolean
  totalNetCost?: number
  totalNetPrice?: number
  trackingIdForShipper?: string
  transferId?: number
  vehicleType?: VehicleType
  version: number
  carrierPaymentCurrency?: Currency
  workflowManaged?: boolean
}

export interface OrderDetailWithCustomerCompany extends OrderDetail {
  customerCompany: Company
}

export type OrderStateTransition =
  | 'register'
  | 'unregister'
  | 'lock_carrier'
  | 'remove_carrier'
  | 'dispatch'
  | 'execute'
  | 'complete_operations'
  | 'complete'
  | 'undispatch'
  | 'cancel'

export interface TransferDetail {
  id: number
  version: number
  code: string
  licensePlate: string
  trailerLicensePlate: string
  notes: string
  dispatchedVehicle: VehicleType
  operator: string
  driver: string
}

export interface StepToUpdate {
  id: number
  actualTime?: ISODateString | null
  isDone?: boolean
  delayReason?: string | null
}

export interface Incident {
  type: string
  faultyParty: string
  reason?: string
  comment?: string
}
export interface TransferStep {
  id: number
  name?: string
  type?: StepType
  scheduledTime: ISODateString | null
  estimatedTime: ISODateString | null
  actualTime: ISODateString | null
  timezone: Timezone
  isDone?: boolean
  isAutoSwitched?: boolean
  delayReason?: string | null
  delayReasonUpdatedAt?: string | null
  delayReasonUserId?: string | null
  hasUnsavedActualTime?: boolean
  hasUnsavedStepDone?: boolean
  wasSet?: boolean
  warehouseIndex: number
}

export interface TransferStepActualTime {
  id: number
  actualTime: Timestamp
  timezone: Timezone
  warehouseIndex: number
}

export interface StepActualTime {
  steps: TransferStepActualTime[]
}

export interface CalculatedPrice {
  value: number
  distance: number
  message: string | null
}

export interface TransferStepActualTimeResponse {
  id: number
  actualTime: string
  timezone: string
  warehouseIndex: number
}

export interface StepActualTimeResponse {
  steps: TransferStepActualTimeResponse[]
}

export interface TransferLocation {
  id: number
  trackedId: string
  longitude: number
  latitude: number
  time: ISODateString
  speed: number
  course?: number | null
  battery?: number | null
}

export interface TransferStopover {
  id: number
  type: StopoverType
  warehouseLocation: string
  longitude: number
  latitude: number
  warehouseIndex: number
  timezone: Timezone
  addressId: string
  countryCode: string
  locality: string
  postalCode: string
  streetAndNumber: string
}

export interface ExecutionTransfer {
  version: number
  steps: TransferStep[]
  locations: TransferLocation[]
  stops: TransferStopover[]
  assignedTrackers?: AssignedTracker[]
  delay: number | null
}

export interface TransferInExecution {
  steps: TransferStep[]
  stopovers: TransferStopover[]
  trackedGpsPositions: TransferLocation[]
  transfer: Transfer
  assignedTrackers?: AssignedTracker[]
  delay: number | null
}

export interface CarrierBilling {
  streetAndNumber: string
  locality: string
  postalCode: string
  country: string
  billingEmail: string
  iban: string
  bic: string
}

export interface Transfer {
  version: number
}

export interface TrackerLocation {
  id: number
  trackerId: number
  longitude: number
  latitude: number
  time: ISODateString
  speed: number
  course?: number
  battery?: number
}

export interface AssignedTracker {
  id?: number
  trackerIdentifier: string
  isMoving?: boolean
  isHidden?: boolean
  isOnline?: boolean
  lastUpdate?: ISODateString
  status?: string
  locations?: TrackerLocation[]
}

export interface HereMapsParameters {
  mode: string
  alternatives: number
  truckType: string
}

export interface OrderDistanceTransitTime {
  id: number
  distanceToNext?: number
  transitTimeToNext?: number
}

export interface ActivePartnerManager {
  id: number
  fullName: string
  email: string
  activationDate?: string
  slackWebHook: string
}

export interface IFeasibleEntity {
  id: string
  name: string
}

export interface OrderAssignmentDetail {
  assignmentBy?: { id: number; name: string }
  assignmentType: 'MANUAL' | 'AUTOMATIC'
  baseCost: number
  cancelled_by?: string
  carrier: { id: number; name: string }
  created: ISODateString
  id: number
  modified: ISODateString
  orderId: number
  state: OrderState
  validUntil: ISODateString
}

export interface IExecutionPlan {
  shipmentId: UUID
  stopPlans: IStopPlan[]
}

export interface IStopPlan {
  id: string
  ordinal: string | number
  carrierCommunicatedArrivalStart: ISODateString
  carrierCommunicatedArrivalEnd: ISODateString
}

export interface ITourExecution {
  id: UUID
  shipmentId: UUID
  status: TourExecutionStatus
  stopExecutions: IStopExecution[]
  facilityActionsCollected: boolean
}

export enum TourExecutionStatus {
  IN_TRANSIT = 'in_transit',
  EXECUTED = 'executed'
}

export interface IStopExecution {
  stopPlanId: string
  stopTimestamps: IStopTimestamp[]
  stopCompletedActions: IStopActionCompletion[]
}

export interface IStopActionCompletion {
  actionType: IStopActionType
}

export enum IStopActionType {
  ARRIVAL = 'arrival',
  DEPARTURE = 'departure'
}

export enum IStopTimestampSource {
  USER_INPUTTED = 'user_inputted',
  GEOFENCE = 'geofence'
}

export interface IStopTimestamp {
  timestamp: ISODateString
  actionType: IStopActionType
  source: IStopTimestampSource
}

export interface TransitRampUpStatus {
  shipmentId: UUID
  isRampedUp: boolean | null
}

export interface IShipperProfileResponse {
  companyName: string
  primaryContactNumber: string
  primaryContactComments: string
  invoicingLegalEntity: string
  accountStatus: string
  accountSource: string
  salesforceAccountId: string
  earlyShipperAccess: boolean
  vat: {
    countryCode: string
    number: string
  }
  accountingId: string
  subContractingPolicy?: 'Allows Sub-Contracting' | 'Does Not Allow Sub-Contracting'
  shipperSensitivity?: 'High' | 'Not Sensitive'
  id: string
}

export interface ShipmentV3OrderStop {
  id: string
  stopoverType: string
  warehouseAddress: {
    profileName: string
    fullAddress: string
    timezone: string
    description: string
  }
  referenceNumber?: string
  startDate: string
  endDate: string
  startTime: string
  endTime: string
  arrivalToWarehouse: string
  departureFromWarehouse: string
  distanceToNext?: number
  notes?: string
  contactPerson?: {
    firstName?: string
    lastName?: string
  }
  side?: string
  drop_trailer: boolean
  driver_assistance: boolean
  personal_protective_equipment: {
    [key: string]: boolean
  }
}
