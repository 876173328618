import { OrderStateTransition } from '@/services/'
import { i18n } from '@/plugins/i18n'
import type { CancellationReason } from '@/modules/ordering/types'

export const OFFER_LIST_REFRESH_RATE = 60000 // 1min in ms

export const MIN_IN_PROGRESS_ACTION_DURATION = 1000

export const ORDER_STATES = [
  'NEW',
  'REGISTERED',
  'CARRIER_LOCKED',
  'DISPATCHED',
  'EXECUTED',
  'OPERATIONS_COMPLETED'
]

export const REJECTION_REASONS = [
  {
    id: 'PRICE_TOO_HIGH',
    label: i18n.t('ordering/order-rejection-reasons-price-too-high')
  },
  {
    id: 'NOT_FITTING_THE_REQUIREMENTS',
    label: i18n.t('ordering/order-rejection-reasons-not-fitting-requirements')
  },
  { id: 'LOW_QUALITY', label: i18n.t('ordering/order-rejection-reasons-low-quality') },
  {
    id: 'DIDNT_SEE_OFFER_IN_TIME',
    label: i18n.t('ordering/order-rejection-reasons-didnt-see-offer-in-time')
  },
  {
    id: 'CANCELLED_TOUR',
    label: i18n.t('ordering/order-rejection-reasons-tour-cancelled')
  },
  {
    id: 'LOADING_UNLOADING_SPOT_CHANGED',
    label: i18n.t('ordering/order-rejection-reasons-loading-unloading-spot-change')
  },
  { id: 'OTHER', label: i18n.t('ordering/order-rejection-reasons-other') }
]

export const STOP_TYPES = [
  { label: 'Loading', value: 'LOADING' },
  { label: 'Unloading', value: 'UNLOADING' }
]

export const STATE_TRANSITIONS: { [key: string]: OrderStateTransition } = {
  REGISTER: 'register',
  UNREGISTER: 'unregister',
  LOCK_CARRIER: 'lock_carrier',
  REMOVE_CARRIER: 'remove_carrier',
  DISPATCH: 'dispatch',
  EXECUTE: 'execute',
  COMPLETE_OPERATIONS: 'complete_operations',
  COMPLETE: 'complete',
  UNDISPATCH: 'undispatch',
  CANCEL: 'cancel'
}

export const CARRIER_OFFER_STATES = {
  CREATED: 'CREATED',
  WITHDRAWN: 'WITHDRAWN',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED'
}

export const ASSIGNMENT_TYPES = {
  MANUAL: 'MANUAL',
  AUTOMATIC: 'AUTOMATIC'
}

export const DEFAULT_TIMEZONE = 'Europe/Berlin'

// TODO: Add translations to these status names
export const LOADING_STATUSES = [
  {
    id: 'ON_THE_WAY',
    name: 'On the way'
  },
  { id: 'LOADING_STARTED', name: 'Loading started' },
  { id: 'LOADING_COMPLETED', name: 'Loading completed' }
]

// TODO: Add translations to these status names
export const UNLOADING_STATUSES = [
  {
    id: 'ON_THE_WAY',
    name: 'On the way'
  },
  { id: 'UNLOADING_STARTED', name: 'Unloading started' },
  { id: 'UNLOADING_COMPLETED', name: 'Unloading completed' }
]

export const PALLET_EXCHANGE_TYPES = {
  NOT_REQUIRED: 'NOT_REQUIRED',
  REGULAR_PALLET_EXCHANGE: 'REGULAR_PALLET_EXCHANGE',
  DPL_VIRTUAL: 'DPL_VIRTUAL',
  DPL_STANDORT: 'DPL_STANDORT',
  NOT_FEASIBLE_FOR_CARRIER: 'NOT_FEASIBLE_FOR_CARRIER'
}

// TODO: Add translations to these pallet exchange names
export const PALLET_EXCHANGE_NAMES = {
  [PALLET_EXCHANGE_TYPES.NOT_REQUIRED]: 'Pallet exchange not required',
  [PALLET_EXCHANGE_TYPES.REGULAR_PALLET_EXCHANGE]: 'Regular pallet exchange',
  [PALLET_EXCHANGE_TYPES.DPL_VIRTUAL]: 'DPL virtual',
  [PALLET_EXCHANGE_TYPES.DPL_STANDORT]: 'DPL Standort',
  [PALLET_EXCHANGE_TYPES.NOT_FEASIBLE_FOR_CARRIER]:
    'Pallet exchange not feasible for carrier'
}

export const CARRIER_ASSIGNMENT_STATES = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  EXPIRED: 'EXPIRED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  NOT_ASSIGNED: 'NOT_ASSIGNED',
  NO_CAPACITY: 'NO_CAPACITY',
  CARRIER_BLACKLISTED: 'CARRIER_BLACKLISTED',
  DOMESTIC_CARRIER_RESTRICTED: 'DOMESTIC_CARRIER_RESTRICTED',
  AWAITING_MATCHING_CONFIRMATION: 'AWAITING_MATCHING_CONFIRMATION'
}

export const ORDER_SOURCE_OCTOPUS = 'OCTOPUS_MANUAL'

export const CANCELLATION_REASONS: CancellationReason[] = [
  {
    id: 81,
    name: 'cancelled_by_shipper',
    label: 'Cancelled by shipper',
    description:
      'Shipment cancelled by shipper (eg. not needed anymore, issues with goods, found another truck, etc.)',
    requiresComment: true
  },
  {
    id: 92,
    name: 'automatic_shipment_creation_failed',
    label: 'Automatic shipment creation failure',
    description:
      'Technical issues during automatic shipment creation by integration or automated mastersheet. Shipper did not cancel the request.',
    requiresComment: false
  },
  {
    id: 94,
    name: 'shipment_not_cancelled_carrier_change',
    label: 'Carrier change (Shipment not cancelled)',
    description:
      'sennder gets out of the agreement with a carrier after carrier locking (eg. faulty auto-assignment, Book Now price too high, we have other carrier(s) willing to go lower, or carrier has 2 companies and the wrong one was chosen)',
    requiresComment: false
  },
  {
    id: 93,
    name: 'shipment_not_cancelled_carrier_cancelled',
    label: 'Cancelled by carrier (Shipment not cancelled)',
    description:
      'Carrier bounced, no show, or rejected due to compliance issue. sennder will look for / has found another carrier. Shipper / sennder did not cancel the request',
    requiresComment: false
  },
  {
    id: 80,
    name: 'shipment_not_cancelled_change_requirements',
    label: 'Change in shipment requirements (Shipment not cancelled)',
    description:
      'Change in shipment details (eg. price, location, description, etc.). Shipper did not cancel the request.',
    requiresComment: true
  },
  {
    id: 73,
    name: 'cancelled_by_carrier_or_no_show',
    label: 'Cancelled by carrier or no show',
    description:
      'Carrier cancelled after being locked or did not show up for the appointment',
    requiresComment: true
  },
  {
    id: 78,
    name: 'shipment_not_commited_to_shipper',
    label: 'Shipment not committed to shipper',
    description: 'Shipment was an opportunity, not committed to the shipper',
    requiresComment: false
  },
  {
    id: 76,
    name: 'mistake_by_operator',
    label: 'Mistake by sennder operator',
    description:
      'Shipment cancelled due to incomplete or incorrect requirements communicated to the carrier. Please use this reason only if there is a tangible mistake from sennder in publishing shipment details that lead to miscommunication to the carrier, then to the shipment being given back to the shipper',
    requiresComment: true
  },
  {
    id: 77,
    name: 'lack_of_capacity',
    label: 'Lack of capacity',
    description: 'Shipment cancelled due to no truck found at a reasonable cost',
    requiresComment: true
  },
  {
    id: 75,
    name: 'carrier_rejected_compliance_issue',
    label: 'Carrier rejected due to compliance issue',
    description:
      'Truck or driver rejected by shipper due to lack of compliance (e.g. wrong equipment, safety issue, truck type etc.)',
    requiresComment: true
  },
  {
    id: 82,
    name: 'force_majeure',
    label: 'Force majeure (strike, natural disaster etc)',
    description:
      'Shipment cancelled due to reasons outside shipper/carrier/sennder control (i.e.: road closure due to weather, strikes)',
    requiresComment: true
  }
]
