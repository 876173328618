import { ORCAS_URL } from '@/config'
import { fetchUserRoles } from '@/services/user-service'
import { getLogger } from '@/shell/datadog-logger'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import { DASHBOARD_FEATURE_FLAGS } from '../constants'

/**
 * Generates Orcas referral link
 * @param referralId User UUID
 * @returns Orcas referral link
 */
const generateOrcasReferralLink = (referralId: string): string => {
  let orcasUrl = ORCAS_URL

  const { isActive } = useFeatureFlag()
  if (isActive(DASHBOARD_FEATURE_FLAGS.ENABLE_CARRIER_ONBOARDING_MERGED_TENANTS).value) {
    orcasUrl = ORCAS_URL.replace('sennder.it', 'sennder.com')
  }

  const referralLink = `${orcasUrl}/signup?referral_id=${referralId}&utm_source=internal_referral&utm_medium=internal_referral&utm_campaign=internal_referral`
  return referralLink
}

/**
 * Check if user has CM role
 * @param uuid User UUID
 * @returns boolean indicating if user has CM role
 */
const checkCMRole = async (uuid: string): Promise<boolean> => {
  try {
    const response = await fetchUserRoles(uuid)
    for (const role of response.roles) {
      if (role.name.endsWith('CM')) {
        return true
      }
    }
  } catch (err) {
    getLogger().error(`error retrieving roles for user ${uuid}`, err)
  }
  return false
}

export { generateOrcasReferralLink, checkCMRole }
