import { MATCHING_API_URL } from '@/config'
import { genericClient } from '../clients/generic.client'
import { TransportOfferWithMatches } from '@/types/matching'

export async function getTransportOffersWithMatches(
  shipmentId: string
): Promise<TransportOfferWithMatches[]> {
  const response = await genericClient.get<TransportOfferWithMatches[]>(
    `${MATCHING_API_URL}/ops-portal/api/v1/shipments/${shipmentId}/transport-offers`
  )

  return response.data
}
