import { monitorError } from '@/analytics/monitoring'
import { getShipper } from '@/api/services/shipper-profile.service'
import { Shipper } from '@/types/shipper'
import { ref, computed } from '@vue/composition-api'
import { defineStore } from 'pinia'

export const useShipperStore = defineStore('shipper-store', () => {
  // state
  const loaders = ref<string[]>([])
  const shipper = ref<Shipper | null>(null)

  // getters
  const isLoading = computed<boolean>(() => loaders.value.length > 0)

  // actions
  async function fetchShipper(shipment_id: string, showLoader = true) {
    try {
      if (showLoader) loaders.value.push('shipment')
      shipper.value = await getShipper(shipment_id)
    } catch (err) {
      monitorError(new Error('Failed to fetch shipment'), 'octopus-new-store', {
        err
      })
      throw err
    } finally {
      loaders.value = loaders.value.filter(loader => loader !== 'shipment')
    }
  }

  return {
    loaders,
    isLoading,
    shipper,
    fetchShipper
  }
})
