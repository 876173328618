<template>
  <div data-test="dispatching-carrier-cell">
    <div>
      <span class="carrier-name">{{ carrierName }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: { type: Object, default: null }
  },

  computed: {
    carrierName() {
      return this.order.carrier
        ? this.order.carrier.name || this.order.carrier.company_name
        : '-'
    }
  }
}
</script>

<style lang="scss" scoped>
.carrier-name {
  display: block;
  max-height: 35px;
  overflow: hidden;
}
</style>
