import { FACILITY_PROFILE_API_URL } from '@/config'
import { genericClient } from '../clients/generic.client'
import { FacilityPlan, FacilityProfile } from '@/types/facility-management'

export const getFacilityPlan = async (shipmentId: string): Promise<FacilityPlan> => {
  const response = await genericClient.get<{ plan: FacilityPlan }>(
    `${FACILITY_PROFILE_API_URL}/v2/plans/shipments/${shipmentId}`
  )

  return response.data.plan
}

export const getFacilityProfile = async (profileId: string): Promise<FacilityProfile> => {
  const response = await genericClient.get<{ profile: FacilityProfile }>(
    `${FACILITY_PROFILE_API_URL}/v2/profiles/${profileId}`,
    {
      headers: {
        'x-client-section': 'com.sennder.octopus.shipment'
      }
    }
  )

  return response.data.profile
}
