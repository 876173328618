import { FLEET_MANAGEMENT_API_URL } from '@/config'
import { Driver, FleetResponse, Trailer, Truck } from '@/types/fleet'
import { genericClient } from '../clients/generic.client'

export async function getTruck(truckId: string, carrierId: string): Promise<Truck> {
  const response = await genericClient.get<FleetResponse<Truck>>(
    `${FLEET_MANAGEMENT_API_URL}/api/v3/trucks/${truckId}`,
    {
      headers: {
        'X-Carrier-Id': carrierId
      }
    }
  )

  return response.data.data
}

export async function getTrailer(trailerId: string, carrierId: string): Promise<Trailer> {
  const response = await genericClient.get<FleetResponse<Trailer>>(
    `${FLEET_MANAGEMENT_API_URL}/api/v3/trailers/${trailerId}`,
    {
      headers: {
        'X-Carrier-Id': carrierId
      }
    }
  )

  return response.data.data
}

export async function getDriver(driverId: string, carrierId: string): Promise<Driver> {
  const response = await genericClient.get<FleetResponse<Driver>>(
    `${FLEET_MANAGEMENT_API_URL}/api/v3/drivers/${driverId}`,
    {
      headers: {
        'X-Carrier-Id': carrierId
      }
    }
  )

  return response.data.data
}
