import axios, { AxiosError } from 'axios'
import { getAuth } from '../../../core-config/orchestrated/auth'

const shipperProfileClient = axios.create()

shipperProfileClient.interceptors.request.use(
  async function (config) {
    const authorization = await getAuth().getAuthHeader({
      audience: 'https://api.cloud.sennder.com/shipper-profile',
      scope: 'shipper-profile:read'
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers = {
      ...config.headers,
      Authorization: authorization
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

shipperProfileClient.interceptors.response.use(
  function (response) {
    return response
  },
  function (error: AxiosError) {
    return Promise.reject(error)
  }
)

export { shipperProfileClient }
