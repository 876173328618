<template>
  <Dialog
    ref="editShipmentModal"
    :show="visible"
    :lazy="true"
    :inPlace="true"
    v-bind="$attrs"
    :cancelButtonText="
      $t('ordering-share-header/edit-new-order-modal-dialog-cancel-btn-text')
    "
    :loading="isEditShipmentModalDisabled"
    class="dialog"
    @close="close"
    @confirm="confirm"
    @cancel="close"
    :disabled="isEditShipmentModalDisabled"
  >
    <p class="subtitle">
      <slot />
    </p>
    <DynamicAlertMessage
      v-for="(alert, key) in dynamicPartialEditResultAlerts"
      :key="key"
      :alert="alert"
    />
    <h4 class="subsection-title">
      {{ $t('shipment/shipper-details-title') }}
    </h4>
    <CustomerDetailsForm
      ref="customerDetailsForm"
      v-model="customerDetailsValue"
      data-test="customer-details-form"
      :contractTypeVisible="contractTypeVisible"
      :isCopied="isCopied"
      :customerDisabled="true"
      :contactDisabled="true"
      :areContactFieldsVisible="false"
      :referenceNumberDisabled="isEditShipmentModalDisabled"
      :trackingIdForShipperDisabled="isEditShipmentModalDisabled"
      :addressDisabled="isEditShipmentModalDisabled"
    />
    <h4 class="subsection-title">
      {{ $t('shipment/edit-modal-route-section-title') }}
    </h4>
    <AlertMessage type="info">
      {{ $t('ordering/enter-locale-time-alert-message') }}
    </AlertMessage>
    <AlertMessage type="warning" v-if="disableRescheduling">
      {{
        $t('shipment/edit-modal-rescheduling-blocked-due-to-chartering-warning-message')
      }}
    </AlertMessage>
    <OrderStopForm
      ref="stopForm"
      :scheduleDisabled="disableRescheduling"
      v-model="stops"
      :stopTypeDisabled="false"
      :warehouseAddressDisabled="true"
      :compactView="true"
      :disabled="isEditShipmentModalDisabled"
      data-test="edit-shipment-stop-form"
      @delete="removeStop"
    />
    <h4 if="shipment" class="title subsection-title">
      {{ $t('ordering/registration-stage-load-title') }}
    </h4>
    <loading-spinner v-if="isLoading" />
    <LoadSection
      v-else-if="shipment"
      ref="loadForm"
      :disabled="isEditShipmentModalDisabled"
      :orderStatus="orderStatus"
      :allowed-vehicle-types="allowedVehicleTypes"
      :vehicle-type="vehicleType"
      :load-quantity.sync="shipment.loads[0].quantity"
      :load-unit-type.sync="shipment.loads[0].load_unit.type"
      :load-weight.sync="shipment.loads[0].total_weight_in_kg"
      :load-length.sync="shipment.loads[0].load_unit.length_in_meters"
      :load-height.sync="shipment.loads[0].load_unit.height_in_meters"
      :load-width.sync="shipment.loads[0].load_unit.width_in_meters"
      :load-description.sync="shipment.loads[0].load_unit.description"
      :sealable.sync="shipment.loads[0].load_requirements.sealed_requirement.is_required"
      :code-xl.sync="shipment.loads[0].load_requirements.vehicle_requirement.code_xl"
      :dock-loading.sync="
        shipment.loads[0].load_requirements.warehouse_loading_requirement.dock_loading
      "
      :side-loading.sync="
        shipment.loads[0].load_requirements.warehouse_loading_requirement.side_loading
      "
      :top-loading.sync="
        shipment.loads[0].load_requirements.warehouse_loading_requirement.top_loading
      "
      @update:vehicleType="setPreferredVehicleSelect"
      @update:allowedVehicleTypes="setAllowedVehicleTypes"
    ></LoadSection>
    <RolledTransportForm
      v-if="displayComponent && originalShipment"
      ref="rolledTransportForm"
      :orderId="originalShipment.shipment_meta.order_id"
      :shipmentId="originalShipment.id"
      class="rolled-transport-form"
      data-test="rolled-transport-form"
      @submit="onIncidentReported"
    />
  </Dialog>
</template>

<script>
import CustomerDetailsForm from '@/modules/ordering/components/shared/header/CustomerDetailsForm.vue'
import OrderStopForm from '@/modules/ordering/components/shared/stops/OrderStopsForm.vue'
import LoadSection from '@/modules/shipment/components/LoadSection.vue'
import DynamicAlertMessage from '@/modules/ordering/components/shared/DynamicAlertMessage.vue'
import { RolledTransportForm } from '@sennder/incident-management'

import clone from 'lodash/clone'
import { ref, watch, onBeforeUnmount, computed } from '@vue/composition-api'
import { i18n } from '@/plugins/i18n'
import useStore from '@/compositions/useStore'
import useStateReset from '@/compositions/useStateReset'
import useRolledTransport from '@/modules/ordering/compositions/useRolledTransport'
import {
  SHIPMENT_EDIT_REQUEST_PARTIAL_UPDATES_STATUS,
  SHIPMENT_EDIT_REQUEST_MAIN_STATUS
} from '@/modules/shipment/constants'

export default {
  components: {
    CustomerDetailsForm,
    OrderStopForm,
    LoadSection,
    RolledTransportForm,
    DynamicAlertMessage
  },
  props: {
    shipment: {
      type: Object,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    orderStatus: {
      type: String,
      required: true
    },
    shipmentEditRequestMainState: {
      type: String,
      default: 'initial'
    },
    isFetchingShipmentEditRequestState: {
      type: Boolean,
      default: false
    },
    disableRescheduling: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const store = useStore()

    const customerDetailsForm = ref(null)
    const rolledTransportForm = ref(null)
    const editShipmentModal = ref(null)
    const visible = ref(false)
    const isCopied = ref(false)
    const originalShipment = ref(null)
    const customerDetailsValue = ref({
      customer: null,
      address: null,
      referenceNumber: null,
      contact: null,
      regularity: null,
      trackingIdForShipper: null
    })
    const contractTypeVisible = ref(false)
    const shipmentModalCloseTimeoutId = ref(null)

    const isEditShipmentModalDisabled = ref(false)
    const removedStopIds = ref([])
    const stopForm = ref(null)
    const loadForm = ref(null)
    const stops = ref([])
    const originalStops = ref([])
    const preferredVehicleType = ref(null)
    const acceptableReplacementVehicleTypes = ref([])
    const payload = {}

    const dynamicPartialEditResultAlerts = computed(() => {
      return [
        {
          condition: isSavingInProgress(),
          type: 'info',
          message: i18n.t('shipment/edit-modal-saving-in-progress-alert-message')
        },
        {
          condition: isUpdateDone('scheduleUpdate'),
          type: 'success',
          message: i18n.t(
            'shipment/edit-modal-schedule-successfully-updated-alert-message'
          )
        },
        {
          condition: isUpdateDenied('scheduleUpdate'),
          type: 'error',
          message: i18n.t('shipment/edit-modal-schedule-failed-alert-message')
        },
        {
          condition: isUpdateDone('referenceUpdate'),
          type: 'success',
          message: i18n.t(
            'shipment/edit-modal-reference-successfully-updated-alert-message'
          )
        },
        {
          condition: isUpdateDenied('referenceUpdate'),
          type: 'error',
          message: i18n.t('shipment/edit-modal-reference-failed-alert-message')
        },
        {
          condition: isUpdateDone('vehicleRequirementUpdate'),
          type: 'success',
          message: i18n.t(
            'shipment/edit-modal-vehicle-successfully-updated-alert-message'
          )
        },
        {
          condition: isUpdateDenied('vehicleRequirementUpdate'),
          type: 'error',
          message: i18n.t('shipment/edit-modal-vehicle-failed-alert-message')
        },
        {
          condition: isUpdateDone('trackingRequirementUpdate'),
          type: 'success',
          message: i18n.t(
            'shipment/edit-modal-tracking-successfully-updated-alert-message'
          )
        },
        {
          condition: isUpdateDenied('trackingRequirementUpdate'),
          type: 'error',
          message: i18n.t('shipment/edit-modal-tracking-failed-alert-message')
        }
      ]
    })

    const shipmentEditPartialUpdate = computed(
      () => store.state.shipment.shipmentEditPartialUpdate
    )

    const vehicleType = computed(() => {
      return (
        preferredVehicleType.value ||
        props.shipment?.loads?.[0]?.load_requirements?.vehicle_requirement
          ?.preferred_vehicle_type
      )
    })

    const allowedVehicleTypes = computed(() => {
      return acceptableReplacementVehicleTypes.value.length
        ? acceptableReplacementVehicleTypes.value
        : props.shipment.loads[0].load_requirements.vehicle_requirement
            .acceptable_replacement_vehicle_types
    })

    watch(
      () => props.shipmentEditRequestMainState,
      newVal => {
        if (visible.value) {
          if (newVal === SHIPMENT_EDIT_REQUEST_MAIN_STATUS.PENDING)
            isEditShipmentModalDisabled.value = true
          else if (
            newVal === SHIPMENT_EDIT_REQUEST_MAIN_STATUS.UPDATED ||
            newVal === SHIPMENT_EDIT_REQUEST_MAIN_STATUS.ERROR
          ) {
            isEditShipmentModalDisabled.value = false
            clearModalTimeout()
            shipmentModalCloseTimeoutId.value = setTimeout(() => {
              visible.value = false
            }, 4000)
          }
        }
      }
    )

    const { displayComponent } = useRolledTransport({
      originalStops,
      stops
    })

    const isUpdateDone = updateType => {
      return (
        props.shipmentEditRequestMainState ===
          SHIPMENT_EDIT_REQUEST_MAIN_STATUS.UPDATED &&
        shipmentEditPartialUpdate.value[updateType] ===
          SHIPMENT_EDIT_REQUEST_PARTIAL_UPDATES_STATUS.DONE
      )
    }

    const isUpdateDenied = updateType => {
      return (
        props.shipmentEditRequestMainState ===
          SHIPMENT_EDIT_REQUEST_MAIN_STATUS.UPDATED &&
        shipmentEditPartialUpdate.value[updateType] ===
          SHIPMENT_EDIT_REQUEST_PARTIAL_UPDATES_STATUS.DENIED
      )
    }

    const isSavingInProgress = () => {
      return (
        isEditShipmentModalDisabled.value &&
        props.shipmentEditRequestMainState === SHIPMENT_EDIT_REQUEST_MAIN_STATUS.PENDING
      )
    }

    const clearModalTimeout = () => {
      if (shipmentModalCloseTimeoutId.value) {
        clearTimeout(shipmentModalCloseTimeoutId.value)
        shipmentModalCloseTimeoutId.value = null
      }
    }

    const show = (
      {
        customer,
        referenceNumber,
        contact,
        regularity,
        showContractTypeField,
        trackingIdForShipper,
        isCopiedParam
      } = {},
      initialStops
    ) => {
      originalShipment.value = props.shipment
      visible.value = true
      contractTypeVisible.value = showContractTypeField
      isCopied.value = isCopiedParam
      customerDetailsValue.value = {
        customer,
        referenceNumber,
        contact,
        regularity,
        trackingIdForShipper
      }
      stops.value = initialStops.map(clone)
      originalStops.value = initialStops
    }

    const setPreferredVehicleSelect = vehicle => {
      preferredVehicleType.value = vehicle
    }

    const setAllowedVehicleTypes = types => {
      acceptableReplacementVehicleTypes.value = types
    }

    const onIncidentReported = () => {
      emit('incident-reported')
    }

    const removeStop = async ({ stop, index }) => {
      if (stop.id) {
        removedStopIds.value.push(stop.id)
      }
      stops.value.splice(index, 1)
    }

    const confirm = () => {
      if (hasErrors()) {
        return
      }

      const reportRolledTransportIncident = () => {
        return rolledTransportForm.value?.submit()
      }

      payload.value = {
        shipment: customerDetailsValue.value,
        stops: stops.value,
        preferredVehicleType: vehicleType.value,
        acceptableReplacementVehicleTypes: allowedVehicleTypes.value
      }

      if (customerDetailsValue.value.regularity) {
        payload.regularity = customerDetailsValue.value.regularity.id
      }

      displayComponent.value ? reportRolledTransportIncident() : Promise.resolve()

      emit('submit', payload.value)
      isEditShipmentModalDisabled.value = true
      reset()
    }

    const close = () => {
      clearModalTimeout()
      visible.value = false
    }

    const hasErrors = () => {
      const hasCustomerDetailsErrors = customerDetailsForm.value?.submit()?.hasErrors
      const hasStopErrors = stopForm.value?.submit()?.hasErrors
      const hasLoadErrors = loadForm.value?.submit()?.hasErrors
      const hasRolledTransportErrors = rolledTransportForm?.value?.validate()?.hasErrors
      return (
        hasStopErrors ||
        hasLoadErrors ||
        hasRolledTransportErrors ||
        hasCustomerDetailsErrors
      )
    }

    const reset = () => useStateReset(['customerDetailsValue'])

    onBeforeUnmount(() => {
      clearModalTimeout()
    })

    return {
      customerDetailsValue,
      customerDetailsForm,
      visible,
      contractTypeVisible,
      close,
      confirm,
      show,
      removeStop,
      displayComponent,
      stopForm,
      loadForm,
      isCopied,
      stops,
      originalShipment,
      rolledTransportForm,
      onIncidentReported,
      editShipmentModal,
      setPreferredVehicleSelect,
      setAllowedVehicleTypes,
      vehicleType,
      allowedVehicleTypes,
      isEditShipmentModalDisabled,
      shipmentEditPartialUpdate,
      dynamicPartialEditResultAlerts,
      SHIPMENT_EDIT_REQUEST_MAIN_STATUS,
      SHIPMENT_EDIT_REQUEST_PARTIAL_UPDATES_STATUS
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog {
  &::v-deep .modal-dialog {
    max-width: 1200px;
  }

  &::v-deep .stage-form-wrap {
    min-width: unset;
  }

  &::v-deep .alert-message {
    margin: 8px 0;

    &__icon {
      transform: rotate(0deg) !important;
    }
  }

  .subsection-title {
    margin: 20px 0;
  }

  .localtime-alert {
    ::v-deep.alert-message__icon {
      transform: rotate(180deg);
    }
  }
}
</style>
