const overrideMockedFlags = {
  'mothership-login': true,
  'use-websocket-notification-service': false,
  'show-shipping-page': false,
  'enable-manual-assignment-flow': false,
  'execution-mandatory-timestamps': false,
  'ENABLE_OCTOPUS-INTERCOMM': false,
  'shipment-executed': false,
  'HIDE_ACCEPT_NOW-CTA-AND-PRICE': false,
  ENABLE_FACILITY_MULTI_SEARCH: false,
  ENABLE_FACILITY_SEARCH_BY_LOCATION: false,
  'SHOW_MANUAL-SUGGESTED-BASE-COST': true,
  ENABLE_FACILITY_MANUAL_SCHEDULING_NW: false,
  ENABLE_FACILITY_SCHEDULING_TAB_READ_ONLY: {},
  'ENABLE_SENN-TMS-REDIRECT-MECHANISM': false,
  'ENABLE_REDIRECT-TO-TMS-BANNER': false,
  'ENABLE_FORCE-REDIRECT-TO-TMS': false,
  'ENABLE_REDIRECT-TO-ARCHIVE': false
}

export default overrideMockedFlags
