import { CustomerAddress, OrderStop } from '@/services'
import { DispatchingOrderDetail } from '../../types'

export class CarrierSearchLookerDashboard {
  private dashboardId = '6287'
  private encondedEmbedUrl: string
  private lookerEmbedUrl = 'https://sennder.de.looker.com/embed/dashboards'

  constructor(loadDetails: LoadDetails) {
    const urlParams = [
      `Origin+Location+2-Digits=${encodeURIComponent(loadDetails.originZipCode)}`,
      `Origin+Country+Code=${encodeURIComponent(loadDetails.originCountryCode)}`,
      `Destination+Location+2-Digits=${encodeURIComponent(
        loadDetails.destinationZipCode
      )}`,
      `Destination+Country+Code=${encodeURIComponent(
        loadDetails.destinationCountryCode
      )}`,
      `Shipper+Name=${encodeURIComponent(loadDetails.shipperName)}`,
      `Dispatched+Vehicle+Type+Vehicle+Type=${encodeURIComponent(
        loadDetails.vehicleType
      )}`,
      'allow_login_screen=true'
    ]

    this.encondedEmbedUrl = `${this.lookerEmbedUrl}/${this.dashboardId}?${urlParams.join(
      '&'
    )}`
  }

  getEmbedUrl(): string {
    return this.encondedEmbedUrl
  }
}

export class LoadDetails {
  constructor(
    public originZipCode: string = '',
    public originCountryCode: string = '',
    public destinationZipCode: string = '',
    public destinationCountryCode: string = '',
    public shipperName: string = '',
    public vehicleType: string = ''
  ) {}
}

export class DeprecatedOrder {
  customerName?: string = ''
  vehicleType?: string = ''
  constructor(order: DispatchingOrderDetail) {
    this.customerName = order.customerName
    this.vehicleType = order.vehicleType
  }
}

export class DeprecatedOrderStop {
  warehouseIndex?: number
  warehouseAddress: CustomerAddress
  constructor(stop: OrderStop) {
    this.warehouseIndex = stop.warehouseIndex
    this.warehouseAddress = stop.warehouseAddress
  }
}

export function mapToLoadDetails(
  order: DeprecatedOrder,
  stops: DeprecatedOrderStop[]
): LoadDetails {
  function calculate2DigitZipCode(countryCode = '', zipCode = ''): string {
    return `${countryCode}${zipCode.slice(0, 2)}`
  }

  const origin = stops.find(stop => stop.warehouseIndex === 0)
  const destination = stops.find(stop => stop.warehouseIndex === stops.length - 1)

  const origin2DigitZipCode = calculate2DigitZipCode(
    origin?.warehouseAddress.countryCode,
    origin?.warehouseAddress.zipCode
  )
  const destination2DigitZipCode = calculate2DigitZipCode(
    destination?.warehouseAddress.countryCode,
    destination?.warehouseAddress.zipCode
  )

  return new LoadDetails(
    origin2DigitZipCode,
    origin?.warehouseAddress.countryCode,
    destination2DigitZipCode,
    destination?.warehouseAddress.countryCode,
    order?.customerName,
    order?.vehicleType
  )
}
