import { monitorError } from '@/analytics/monitoring'
import { getTransportOffersWithMatches } from '@/api/services/matching.service'
import {
  Match,
  MATCH_STATE,
  TransportOffer,
  TransportOfferWithMatches
} from '@/types/matching'
import { computed, ref } from '@vue/composition-api'
import { AxiosError } from 'axios'
import { defineStore } from 'pinia'

export const useMatchingStore = defineStore('matching-store', () => {
  // state
  const loaders = ref<string[]>([])
  const transportOffersWithMatches = ref<TransportOfferWithMatches[] | null>(null)

  // getters
  const isLoading = computed<boolean>(() => loaders.value.length > 0)
  const match = computed<Match | null>(
    () => transportOffersWithMatches.value?.[0]?.matches?.[0] ?? null
  )

  // todo: sort TOs by creation date
  const transportOffer = computed<TransportOffer | null>(
    () => transportOffersWithMatches.value?.[0]?.transport_offer ?? null
  )

  // todo: use find for matches
  const isCFTLMatched = computed<boolean>(
    () => match.value?.state === MATCH_STATE.COMPLETED
  )

  const isCharteringMatched = computed<boolean>(
    () => match.value?.state === MATCH_STATE.CHARTER_MATCHED
  )

  // actions
  async function fetchTransportOfferWithMatches(externalId: string, showLoader = true) {
    try {
      if (showLoader) loaders.value.push('transportOffers')
      transportOffersWithMatches.value = await getTransportOffersWithMatches(externalId)
    } catch (err: unknown) {
      // transport offer may not exist for the shipment, this is not an error
      if ((err as AxiosError).response?.status === 404) return

      monitorError(
        new Error('Failed to fetch transport offers with matches'),
        'octopus-new-store',
        {
          err
        }
      )
      throw err
    } finally {
      loaders.value = loaders.value.filter(loader => loader !== 'transportOffers')
    }
  }

  return {
    loaders,
    isLoading,
    match,
    transportOffer,
    isCFTLMatched,
    isCharteringMatched,
    transportOffersWithMatches,
    fetchTransportOfferWithMatches
  }
})
