






























































































import { PropType, defineComponent } from '@vue/composition-api'
import get from 'lodash/get'
import { placeholderIfEmpty } from '@/global-setup/filters'
import { OrderDetail } from '@/services'
import LabeledText from '@/modules/common/components/LabeledText.vue'
import orderingMappers from '@/modules/ordering/store/mappers'

export default defineComponent({
  components: { LabeledText },
  props: {
    order: { type: Object as PropType<OrderDetail>, required: true },
    useNewLayout: { type: Boolean, default: false }
  },
  setup() {
    return { get, placeholderIfEmpty }
  },
  computed: {
    ...orderingMappers.mapGetters(['carrierId'])
  }
})
