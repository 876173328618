







import Vue from 'vue'
import {
  createRandomString,
  getEnvironmentAliasForFederatedLoader
} from '@/microfrontends/utils'
import { Location, RawLocation, Route } from 'vue-router'
import {
  IUser,
  SharedDataType,
  Tenant
} from '@sennder/senn-node-microfrontend-interfaces'
import useCurrentUser from '@/compositions/useCurrentUser'
import useI18n from '@/compositions/useI18n'
import { getAppTheme } from '@/controllers/environment-detection'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import { OPERATIONS_BACKEND_URL } from '@/config'
import { fetchMicrofrontend } from '@/microfrontends/fetchMicrofrontend'
import { getLogger } from '@/shell/datadog-logger'
import {
  MicrofrontendEvents,
  onRefreshOrderLogs,
  subscribe
} from '@sennder/senn-node-microfrontend-event-bus'
import { refreshOrderLogs } from '@/modules/order-activity-sidebar'
import { getAuthHeader, getCommonHeaders, getPermissions } from '@/services/auth-service'
import { monitorError } from '@/analytics/monitoring'

const NAMESPACE = 'octopuspaymentswidget'
const REMOTE = './octopuspaymentswidgetCreateClaimButton'
const NPM_PATH = 'octopus-payments-widget'
const DEV_PORT = 9002

let abortOctopusPaymentsWidgetController: AbortController | undefined
let refreshOrderLogsAbortController: AbortController | undefined

export default Vue.extend({
  name: 'CreateClaimButton',
  props: {
    externalId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: `${createRandomString()}-CreateClaimButton`,
      isLoaded: null as boolean | null,
      syncChildRouter: null as ((route: RawLocation) => void) | null,
      unmount: null as (() => void) | null
    }
  },
  beforeDestroy() {
    abortOctopusPaymentsWidgetController?.abort()
  },
  destroyed() {
    if (this.unmount) {
      this.unmount()
    }
    refreshOrderLogsAbortController?.abort()
  },
  beforeMount() {
    subscribe<onRefreshOrderLogs>(
      MicrofrontendEvents.onRefreshOrderLogs,
      async () => {
        await refreshOrderLogs()
      },
      { signal: refreshOrderLogsAbortController?.signal }
    )
  },
  async mounted() {
    abortOctopusPaymentsWidgetController = new AbortController()
    refreshOrderLogsAbortController = new AbortController()

    const syncParentRouter = (route: RawLocation) => {
      const currentPath = this.$route.fullPath
      if (
        (typeof route === 'string' && currentPath === route) ||
        (typeof route === 'object' && currentPath === (route as Location).path)
      ) {
        return
      }
      this.$router.push(route)
    }

    try {
      const { user } = useCurrentUser()
      const { locale } = useI18n()
      const { isActive } = useFeatureFlag()

      const mount = await fetchMicrofrontend({
        devPort: DEV_PORT,
        environment: getEnvironmentAliasForFederatedLoader(NAMESPACE),
        moduleFederationPluginName: NAMESPACE,
        npmName: NPM_PATH,
        mountExposedName: REMOTE,
        logger: getLogger()
      })

      const mfData = mount(`#${this.id}`, {
        data: {
          type: SharedDataType.OCTOPUS,
          env: {
            OPERATIONS_BACKEND_URL
          },
          user: user.value as IUser,
          language: locale.value,
          tenant: getAppTheme() as Tenant,
          abortController: abortOctopusPaymentsWidgetController,
          externalId: this.externalId,
          featureFlags: {
            ENABLE_CLAIM_ACCESSORIAL: isActive('ENABLE_CLAIM_ACCESSORIAL').value
          }
        },
        callbacks: {
          getToken: getAuthHeader,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          getAuth0Token: async (audience: string, scope?: string) => {
            return getAuthHeader({
              audience,
              scope
            })
          },
          getAuthHeader,
          syncParentRouter,
          segmentTrackEvent: null,
          onUnauthorized: () => {},
          onUnhandledError: error => {
            Vue.config.errorHandler(
              error,
              null,
              'Octopus Payments Widget - Claims Button'
            )
          },
          getCommonHeaders,
          getPermissions
        },
        providers: {
          logger: undefined,
          notifications: undefined,
          translations: undefined,
          segment: undefined,
          analytics: undefined,
          monitoring: undefined
        }
      })
      if (mfData.syncChildRouter) {
        this.syncChildRouter = mfData.syncChildRouter
        this.$watch(
          '$route',
          (to: Route) => {
            mfData.syncChildRouter(to.fullPath)
          },
          {
            immediate: true
          }
        )
      }
      if (mfData.unmount) {
        this.unmount = mfData.unmount
      }
      this.isLoaded = true
    } catch (e) {
      monitorError(e, 'CreateClaimButton MF bootstrap error:', {
        type: 'bootstrap'
      })
      this.isLoaded = false
    }
  }
})
