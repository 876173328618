import {
  ORDER_STATUSES,
  CLAIM_STATUSES,
  Currency
} from '@/modules/ordering/legacy/constants'
export type ISODateString = string

export const ISSUE_CATEGORY_LABELS = {
  WAITING_SURCHARGE: 'finance/global-issue-categories-waiting-surcharge',
  PALLET_SURCHARGE: 'finance/global-issue-categories-pallet-surcharge',
  CANCELLATION_SURCHARGE: 'finance/global-issue-categories-cancellation-surcharge',
  EXTRA_DISTANCE_LOADING_SURCHARGE:
    'finance/global-issue-categories-extra-distance-surcharge',
  DIESEL_SURCHARGE: 'finance/global-issue-categories-diesel-surcharge',
  AUTOMATIC_DIESEL_SURCHARGE:
    'finance/global-issue-categories-automatic-diesel-surcharge',
  OTHER_SURCHARGE: 'finance/global-issue-categories-other-surcharge',
  INSURANCE_SURCHARGE: 'finance/global-issue-categories-insurance-surcharge',
  DOUBLE_DRIVER_SURCHARGE: 'finance/global-issue-categories-double-driver-surcharge',
  FERRY_SURCHARGE: 'finance/global-issue-categories-ferry-surcharge',
  SEMI_TRAILER_SURCHARGE: 'finance/global-issue-categories-semi-trailer-surcharge'
} as const

export type IssueCategoryCode = keyof typeof ISSUE_CATEGORY_LABELS

export const ISSUE_CATEGORIES_CODES = Object.keys(
  ISSUE_CATEGORY_LABELS
) as IssueCategoryCode[]

export interface OrderPaymentInformation {
  automaticPaymentStartTimestamp: ISODateString
  isCarrierCostClosed: boolean
  isCustomerPriceClosed: boolean
  paymentDate: ISODateString
  isEligibleForAutomaticCreditNotes: boolean
  carrierCostClosedTimestamp?: ISODateString
  customerPriceClosedTimestamp?: ISODateString
  creditNoteWasSent: boolean
}

interface DefaultDecimalDataclass {
  id: number
  correctionAmount: number
  notes: string
}

export interface BaseCharge {
  chargeCorrections: DefaultDecimalDataclass[]
  id: number
  initialAmount: number
}

export interface ExtraCharge {
  amount: number
  amountCharge: BaseCharge
  id: number
  issueCategory: IssueCategoryCode
  summary: string
}

export interface CarrierCharges {
  orderId: number
  baseCostCharge: BaseCharge
  carrierExtraCharges: ExtraCharge[]
}
export interface AddCarrierExtraCharge {
  order: number
  amount: number
  summary: string
  issueCategory: IssueCategoryCode
}

export interface CustomerCharges {
  orderId: number
  basePriceCharge: BaseCharge
  customerExtraCharges: ExtraCharge[]
}
export interface UnifiedCharges {
  orderId: number
  baseCharge: BaseCharge
  extraCharges?: ExtraCharge[]
}

export interface Invoice {
  id?: number
  orderId: number
  number: string
  amount: number
  issueDate: string
  isCancelled?: boolean
  isCreditNote: boolean
  filePath?: string
}

export interface ExtendedInvoice extends Invoice {
  invoiceDateHuman?: string
}

export interface FinanceInvoiceData {
  totalCarrierInvoiceAmount: number
  computedAmountToInvoiceForCarrier: number
  carrierInvoices: Invoice[]
}

export interface ShipperInvoiceAggregate {
  totalShipperInvoiceAmount: number
  remainingAmountToInvoiceForShipper: number
  shipperInvoices: Invoice[]
}

export type ExtraChargeType = 'CARRIER' | 'CUSTOMER'

/* Claims types */

export type ClaimReporter = 'CARRIER' | 'SHIPPER'
export type ClaimReporterLowerCase = 'carrier' | 'shipper'
export type ClaimFaultyParty = 'CARRIER' | 'SHIPPER' | 'INTERNAL' | 'THIRD_PARTY'

export interface ClaimType {
  code: string
  subtypes: ClaimSubtype[]
}

export interface ClaimSubtype {
  code?: string
  stopoverId?: number
  stopoverDescription: string
}

export type ClaimStatus = typeof CLAIM_STATUSES[number]

export interface ClaimTransition {
  code: ClaimStatus
}

/**
 * Interface that defines common fields between the interfaces for creating claims
 * (shipper and carrier side) and the interface for the detail of claims.
 */
export interface BaseClaim {
  orderId: number
  reporter: ClaimReporter
  faultyParty: ClaimFaultyParty
  claimType: string
  stopoverId: number
  claimSubtype: string
  incidentTime: string
}

export interface CreateClaimFile {
  originalName: string
  uploadDirectory: string
  fileSize: number
}

/**
 * Interface  for creating a claim
 */
export interface CreateClaim extends BaseClaim {
  requestedAmount: number
  description: string
  files: CreateClaimFile[]
}

export interface ClaimSideInfo {
  description: string
  requestedAmount: number
  status: ClaimStatus
  statusAvailableTransitions: ClaimTransition[]
  finalAmount: number
  approvalNote: string
  rejectionNote: string
}

/**
 * Claim detail interface.
 * Define shape of claim object returned by the API
 */
export interface Claim extends BaseClaim {
  id: number
  carrier: ClaimSideInfo
  shipper: ClaimSideInfo
  filesCount: number
  allowEditingFiles: boolean
  stopoverDescription: string
}

export interface ClaimDocument {
  id: number
  businessName: string
  created: ISODateString
  fileSize: number
}

export interface AggregationValues {
  overallRevenue: number
  overallCost: number
  count: number
}
export interface PresignedUpload {
  url: string
  fields: { [key: string]: string }
  uploadDirectory: string
}

export interface ApproveClaimParams {
  claimId: number
}
export interface ApproveShipperClaimParams {
  claimId: number
  shipperFinalAmount: number
}
export interface ApproveCarrierClaimParams {
  claimId: number
  carrierFinalAmount: number
}
export interface RejectShipperClaimParams {
  claimId: number
  shipperRejectionNote: string
}
export interface RejectCarrierClaimParams {
  claimId: number
  carrierRejectionNote: string
}

export interface EditClaimParams {
  claimId: number
  requestedAmount: number
  description: string
  side: ClaimReporter
}

export interface EditClaimParamsShipper {
  claimId: number
  shipperRequestedAmount: number
  shipperDescription: string
}

export interface FinanceOrderLog {
  id: number
  user: string
  date: ISODateString
  logType: string
  logData: { [key: string]: unknown }
  message: string
}

export interface CarrierBilling {
  streetAndNumber: string
  locality: string
  postalCode: string
  country: string
  billingEmail: string
  iban: string
  bic: string
}

export type Stop = {
  countryCode: string
  index: number
  postalCode: string
}

export interface GetClaimBoardClaimInfo {
  id: number
  created: string
  createdBy?: number
  orderId: number
  claimType: string
  claimSubtype: string
  stopoverDescription: string
  shipper: {
    requestedAmount?: number
    finalAmount?: number
    status: ClaimStatus
    currency?: Currency
  }
  carrier: {
    requestedAmount?: number
    finalAmount?: number
    status: ClaimStatus
    currency?: Currency
  }
  filesCount: number
  orderIdForStaff?: string
  orderState?: keyof typeof ORDER_STATUSES
  accountManagerFirstName?: string
  accountManagerLastName?: string
  partnerManagerFirstName?: string
  partnerManagerLastName?: string
  createdByFullName?: string
  stop?: Stop
}

export type BoardClaimAccessorialsSide = {
  status: string
  requested_amount: Amount
  final_amount: Amount | null
}

export type ClaimStop = {
  index: number
  country_code: string
  postal_code: string
}

export type Amount = {
  value: string
  currency: Currency
}

export type ColumnUrlParams = {
  page: string | number
  size: string | number
}

export interface ShipperInvoiceGenerationRequirements {
  languages: string[]
  paymentDate: string
}

export interface ServiceError {
  errorCode: string
  errorData: Record<string, unknown>
  errorMessage?: string
}

export interface CostCentre {
  code: string
  shortDescription: string
  longDescription: string
}

export interface PODUploadParams {
  fileName: string
  fileSize: number
  podUuid: string
  documentState: 'APPROVED' | 'SUBMITTED'
}
