import { createInitialState } from './state'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'
import { getAssignmentStatesOptions } from '../constants'

import {
  createFilter,
  createBooleanFilter,
  createNumericFilter,
  createRadioFilter,
  createBasicListFilter,
  createEntityListFilter,
  createTeamLeadsFilter,
  createDatesFilter,
  createOrderStateFilter
} from './factories'

import { fetchCarrierById } from '@/services/carrier-service'
import { fetchCarrierById as fetchCarrierByIdFromProfileService } from '@/services/carrier-profile-service'
import { fetchCustomerById } from '@/services/customer-service'
import { fetchUserById, fetchUserByUuid } from '@/services/user-service'

import { VEHICLE_TYPES } from '@sennder/octopus-constants'
import { CLAIM_STATUSES_LABELS } from '@sennder/finance-frontend'

/**
 * @typedef {import('./factories/filter').FilterValue} DefaultFilterValue
 * @typedef {import('./factories/boolean').FilterValue} BooleanFilterValue
 * @typedef {import('./factories/three-way-radio').FilterValue} RadioFilterValue
 * @typedef {import('./factories/basic-list').FilterValue} BasicListFilterValue
 * @typedef {import('./factories/entity-list').FilterValue} EntityListFilterValue
 * @typedef {import('./factories/team-leads').FilterValue} TeamLeadsFilterValue
 * @typedef {import('./factories/dates').FilterValue} DateFilterValue
 */

/**
 * @param {{
 *  accountManagers: EntityListFilterValue,
 *  juniorAccountManagers: EntityListFilterValue,
 *  assignmentStates: BasicListFilterValue,
 *  carriers: EntityListFilterValue,
 *  creditNoteWasSent: RadioFilterValue,
 *  customers: EntityListFilterValue,
 *  destinations: BasicListFilterValue,
 *  destinationWildCard: DefaultFilterValue,
 *  destinationCity: DefaultFilterValue,
 *  destinationCountryCodes: BasicListFilterValue,
 *  destinationPostalCode: DefaultFilterValue,
 *  radius: DefaultFilterValue,
 *  originRadius: DefaultFilterValue,
 *  destinationRadius: DefaultFilterValue,
 *  earliestDropoff: DateFilterValue,
 *  earliestPickup: DateFilterValue,
 *  hasCarrierInvoices: RadioFilterValue,
 *  hasDiscount: RadioFilterValue,
 *  hasMissingDocumentRequests: RadioFilterValue,
 *  hasNoCarrier: BooleanFilterValue,
 *  hasNoOperator: BooleanFilterValue,
 *  idForStaff: DefaultFilterValue,
 *  idsForStaff: BasicListFilterValue,
 *  invoiceNumber: DefaultFilterValue,
 *  isCarrierBilled: RadioFilterValue,
 *  isCustomerBilled: RadioFilterValue,
 *  isEligibleForAutomaticCreditNotes: RadioFilterValue,
 *  latestDropoff: DateFilterValue,
 *  latestPickup: DateFilterValue,
 *  needsToBeValidated: RadioFilterValue,
 *  orderCancelled: RadioFilterValue,
 *  orderStates: BasicListFilterValue,
 *  origins: BasicListFilterValue,
 *  originWildCard: DefaultFilterValue,
 *  originCity: DefaultFilterValue,
 *  originCountryCodes: BasicListFilterValue,
 *  originPostalCode: DefaultFilterValue,
 *  partnerManagers: EntityListFilterValue,
 *  referenceNumber: DefaultFilterValue,
 *  referenceNumbers: DefaultFilterValue,
 *  teamLeads: TeamLeadsFilterValue,
 *  pickupRange: DefaultFilterValue,
 *  dropoffRange: DefaultFilterValue,
 *  transferCode: DefaultFilterValue,
 *  vehicleTypes: EntityListFilterValue,
 *  claimStatuses: EntityListFilterValue,
 *  dispatchingConfirmationSent: RadioFilterValue,
 *  filledLicensePlates:RadioFilterValue,
 *  licensePlateNumber: DefaultFilterValue,
 *  isRemoved: DefaultFilterValue
 * }} defaults
 * @param {Array} hiddenChips
 * @returns {any} - vuex module instance
 */
export const createFilters = (defaults = {}, config = {}) => ({
  namespaced: true,
  state: createInitialState(config),
  mutations,
  getters,
  actions,
  modules: {
    pickupRange: createDatesFilter({
      names: ['pickup_time_start', 'pickup_time_end'],
      urlParamNames: ['pickup_start_date', 'pickup_end_date'],
      esNames: [
        'order.loading_stopover.arrival_to_warehouse',
        'order.loading_stopover.departure_from_warehouse'
      ],
      initialValues: [
        defaults.pickupRange ? defaults.pickupRange.startDate : null,
        defaults.pickupRange ? defaults.pickupRange.endDate : null
      ],
      emptyValues: [null, null],
      updateName: 'pickupRange',
      filterLabels: ['filters/pickup-start-label', 'filters/pickup-end-label'],
      isRangeFilter: true,
      keepTime: true
    }),
    dropoffRange: createDatesFilter({
      names: ['dropoff_time_start', 'dropoff_time_end'],
      urlParamNames: ['dropoff_time_start', 'dropoff_time_end'],
      esNames: [
        'order.unloading_stopover.arrival_to_warehouse',
        'order.unloading_stopover.departure_from_warehouse'
      ],
      initialValues: [
        defaults.dropoffRange ? defaults.dropoffRange.startDate : null,
        defaults.dropoffRange ? defaults.dropoffRange.endDate : null
      ],
      emptyValues: [null, null],
      updateName: 'dropoffRange',
      filterLabels: ['filters/dropoff-start-label', 'filters/dropoff-end-label'],
      isRangeFilter: true,
      keepTime: true
    }),
    customers: createEntityListFilter({
      name: 'customer_ids',
      esName: 'order.shipper_id',
      urlParamName: 'customers',
      getEntityFromId: fetchCustomerById,
      initialValue: defaults.customers,
      updateName: 'customers',
      filterLabel: 'filters/customer-label'
    }),
    isRemoved: createFilter({
      name: 'is_removed',
      esField: 'order.is_removed',
      initialValue: defaults.isRemoved,
      emptyValue: '',
      updateName: 'isRemoved',
      filterLabel: 'filters/is-removed-label'
    }),
    referenceNumbers: createBasicListFilter({
      name: 'reference_numbers',
      initialValue: defaults.referenceNumbers,
      updateName: 'referenceNumbers',
      filterLabel: 'filters/reference-label'
    }),
    referenceNumber: createFilter({
      name: 'reference_number',
      esField: 'order.reference_number',
      initialValue: defaults.referenceNumber,
      emptyValue: '',
      updateName: 'referenceNumber',
      filterLabel: 'filters/customer-reference-label'
    }),
    transferCode: createFilter({
      name: 'transfer_code',
      esField: 'transfer.code',
      initialValue: defaults.transferCode,
      emptyValue: '',
      updateName: 'transferCode',
      filterLabel: 'filters/transfer-code-label'
    }),
    idForStaff: createFilter({
      name: 'id_for_staff',
      esField: 'order.id_for_staff',
      initialValue: defaults.idForStaff,
      emptyValue: '',
      updateName: 'idForStaff',
      filterLabel: 'filters/sennder-reference-label'
    }),
    idsForStaff: createBasicListFilter({
      name: 'ids_for_staff',
      esName: 'order.id_for_staff',
      initialValue: defaults.idsForStaff,
      updateName: 'idsForStaff',
      filterLabel: 'filters/sennder-reference-label'
    }),
    licensePlateNumber: createFilter({
      name: 'license_plate_number',
      esField: 'transfer.license_plate',
      initialValue: defaults.licensePlateNumber,
      emptyValue: '',
      updateName: 'licensePlateNumber',
      filterLabel: 'filters/license-plate-number-label'
    }),
    assignmentStates: createBasicListFilter({
      name: 'assignment_states',
      initialValue: defaults.assignmentStates,
      updateName: 'assignmentStates',
      filterLabel: 'filters/assingment-status-label',
      humanizeValue: assignmentState => {
        return getAssignmentStatesOptions().find(
          option => option.value === assignmentState
        )?.label
      }
    }),
    carriers: createEntityListFilter({
      name: 'carrier_ids',
      esName: 'carrierMatch.carrier_id',
      urlParamName: 'carriers',
      getEntityFromId: fetchCarrierById,
      initialValue: defaults.carriers,
      updateName: 'carriers',
      filterLabel: 'filters/carrier-label'
    }),
    esCarriers: createEntityListFilter({
      name: 'es_carrier_ids',
      urlParamName: 'es_carriers',
      getEntityFromId: fetchCarrierByIdFromProfileService,
      initialValue: defaults.es_carriers,
      updateName: 'esCarriers',
      filterLabel: 'filters/es-carrier-label'
    }),
    hasNoCarrier: createBooleanFilter(
      'has_no_carrier',
      defaults.hasNoCarrier || false,
      'hasNoCarrier',
      'filters/no-carrier-order-label'
    ),
    orderStates: createOrderStateFilter(defaults.orderStates),
    contractTypes: createBasicListFilter({
      name: 'contract_types',
      initialValue: defaults.contractTypes,
      updateName: 'contractTypes',
      filterLabel: 'filters/contract-type-label'
    }),
    orderSources: createBasicListFilter({
      name: 'order_sources',
      initialValue: defaults.orderSources,
      updateName: 'orderSources',
      filterLabel: 'filters/order-source-label'
    }),
    originCountryCodes: createBasicListFilter({
      name: 'origin_country_codes',
      initialValue: defaults.originCountryCodes,
      esName: 'order.loading_stopover.company_address.address.country_code.keyword',
      esWord: 'terms',
      updateName: 'originCountryCodes',
      filterLabel: 'filters/origin-country-label'
    }),
    destinationCountryCodes: createBasicListFilter({
      name: 'destination_country_codes',
      esName: 'order.unloading_stopover.company_address.address.country_code.keyword',
      esWord: 'terms',
      initialValue: defaults.destinationCountryCodes,
      updateName: 'destinationCountryCodes',
      filterLabel: 'filters/destination-country-label'
    }),

    originCity: createFilter({
      name: 'origin_city',
      initialValue: defaults.originCity,
      emptyValue: '',
      updateName: 'originCity',
      filterLabel: 'filters/origin-city-label'
    }),
    originPostalCode: createFilter({
      name: 'origin_postal_code',
      initialValue: defaults.originPostalCode,
      emptyValue: '',
      updateName: 'originPostalCode',
      filterLabel: 'filters/origin-postal-code-label'
    }),
    originWildCard: createFilter({
      name: 'origin_wild_card',
      initialValue: defaults.originWildCard,
      emptyValue: '',
      updateName: 'originWildCard',
      filterLabel: 'filters/origin-search-label',
      esFields: [
        'order.loading_stopover.company_address.address.country_post_code.keyword',
        'order.loading_stopover.company_address.address.locality.keyword'
      ],
      esWord: 'should',
      esHasPrefix: true,
      esPrefixFields: [
        'order.loading_stopover.company_address.address.country_post_code.keyword',
        'order.loading_stopover.company_address.address.postal_code.keyword'
      ]
    }),
    origins: createBasicListFilter({
      name: 'origins',
      initialValue: defaults.origins,
      updateName: 'origins',
      filterLabel: 'filters/origin-list-search-label'
    }),
    destinationCity: createFilter({
      name: 'destination_city',
      initialValue: defaults.destinationCity,
      emptyValue: '',
      updateName: 'destinationCity',
      filterLabel: 'filters/destination-city-label'
    }),
    destinationPostalCode: createFilter({
      name: 'destination_postal_code',
      initialValue: defaults.destinationPostalCode,
      emptyValue: '',
      updateName: 'destinationPostalCode',
      filterLabel: 'filters/destination-postal-code-label'
    }),
    destinationWildCard: createFilter({
      name: 'destination_wild_card',
      initialValue: defaults.destinationWildCard,
      emptyValue: '',
      updateName: 'destinationWildCard',
      filterLabel: 'filters/destination-search-label',
      esFields: [
        'order.unloading_stopover.company_address.address.country_post_code.keyword',
        'order.unloading_stopover.company_address.address.locality.keyword'
      ],
      esWord: 'should',
      esHasPrefix: true,
      esPrefixFields: [
        'order.unloading_stopover.company_address.address.country_post_code.keyword'
      ]
    }),
    destinations: createBasicListFilter({
      name: 'destinations',
      initialValue: defaults.destinations,
      updateName: 'destinations',
      filterLabel: 'filters/destination-list-label'
    }),
    radius: createNumericFilter({
      name: 'radius',
      initialValue: defaults.radius,
      emptyValue: null,
      updateName: 'radius',
      filterLabel: 'filters/radius-label'
    }),
    originRadius: createNumericFilter({
      name: 'origin_radius',
      initialValue: defaults.originRadius,
      emptyValue: null,
      updateName: 'originRadius',
      filterLabel: 'filters/origin-radius-label'
    }),
    destinationRadius: createNumericFilter({
      name: 'destination_radius',
      initialValue: defaults.destinationRadius,
      emptyValue: null,
      updateName: 'destinationRadius',
      filterLabel: 'filters/destination-radius-label'
    }),
    dispatchingConfirmationSent: createRadioFilter(
      'dispatching_confirmation_sent',
      defaults.dispatchingConfirmationSent,
      'dispatchingConfirmationSent',
      'filters/dispatching-confirmation-sent-label'
    ),
    filledLicensePlates: createRadioFilter(
      'has_license_plate',
      defaults.filledLicensePlates,
      'filledLicensePlates',
      'filters/has-license-plate-label'
    ),
    esSeniorAccountManagers: createEntityListFilter({
      name: 'es_senior_account_manager_ids',
      urlParamName: 'es_senior_account_managers',
      getEntityFromId: fetchUserByUuid,
      initialValue: defaults.es_senior_account_managers,
      updateName: 'esSeniorAccountManagers',
      filterLabel: 'filters/senior-account-manager-label'
    }),
    seniorAccountManagers: createEntityListFilter({
      name: 'senior_account_manager_ids',
      esName: 'designatedOperators.senior_account_manager.operator_id', // SENIOR
      urlParamName: 'senior_account_managers',
      optionLabel: 'name',
      getEntityFromId: fetchUserById,
      initialValue: defaults.seniorAccountManagers,
      updateName: 'seniorAccountManagers',
      filterLabel: 'filters/senior-account-manager-label'
    }),
    esAccountManagers: createEntityListFilter({
      name: 'es_account_manager_ids',
      urlParamName: 'es_account_managers',
      getEntityFromId: fetchUserByUuid,
      initialValue: defaults.es_account_managers,
      updateName: 'esAccountManagers',
      filterLabel: 'filters/account-operation-manager-label'
    }),
    accountManagers: createEntityListFilter({
      name: 'account_manager_ids',
      esName: 'designatedOperators.account_manager.operator_id',
      urlParamName: 'account_managers',
      optionLabel: 'name',
      getEntityFromId: fetchUserById,
      initialValue: defaults.accountManagers,
      updateName: 'accountManagers',
      filterLabel: 'filters/account-operation-manager-label'
    }),
    partnerManagers: createEntityListFilter({
      name: 'operator_ids',
      esName: 'transfer.user.mothership_id',
      esWord: 'terms',
      urlParamName: 'partner_managers',
      optionLabel: 'username',
      getEntityFromId: fetchUserById,
      initialValue: defaults.partnerManagers,
      updateName: 'partnerManagers',
      filterLabel: 'filters/partner-manager-label'
    }),
    hasNoOperator: createBooleanFilter(
      'has_no_operator',
      defaults.hasNoOperator || false,
      'hasNoOperator',
      'filters/no-operator-label'
    ),
    teamIds: createTeamLeadsFilter(defaults.teamIds),
    vehicleTypes: createEntityListFilter({
      name: 'vehicle_types',
      esName: 'order.allowed_vehicle_types',
      optionLabel: 'label',
      getEntityFromId: id => ({ label: VEHICLE_TYPES[id], id }),
      initialValue: defaults.vehicleTypes,
      updateName: 'vehicleTypes',
      filterLabel: 'filters/vehicle-type-label'
    }),
    claimStatuses: createEntityListFilter({
      name: 'claim_statuses',
      optionLabel: 'label',
      getEntityFromId: id => ({
        label: CLAIM_STATUSES_LABELS[id],
        id
      }),
      initialValue: defaults.claimStatuses,
      updateName: 'claimStatuses',
      filterLabel: 'filters/claims-status-label'
    }),
    pickupDates: createDatesFilter({
      names: ['pickup_time_start', 'end'],
      urlParamNames: ['earliest_pickup', 'latest_pickup'],
      initialValues: [defaults.earliestPickup || null, defaults.latestPickup || null],
      updateName: 'pickupDates',
      filterLabels: ['filters/pickup-start-time-label', 'filters/pickup-end-time-label']
    }),
    dropoffDates: createDatesFilter({
      names: ['start', 'dropoff_time_end'],
      urlParamNames: ['earliest_dropoff', 'latest_dropoff'],
      initialValues: [defaults.earliestDropoff || null, defaults.latestDropoff || null],
      updateName: 'dropoffDates',
      filterLabels: ['filters/dropoff-start-time-label', 'filters/dropoff-end-time-label']
    }),
    invoiceNumber: createFilter({
      name: 'customer_or_carrier_invoice_number',
      initialValue: defaults.invoiceNumber,
      emptyValue: '',
      urlParamName: 'invoice_number',
      updateName: 'invoiceNumber',
      filterLabel: 'filters/customer-carrier-invoice-label'
    }),
    needsToBeValidated: createRadioFilter(
      'needs_to_be_validated',
      defaults.needsToBeValidated,
      'needsToBeValidated',
      'filters/pods-to-validate-label'
    ),
    hasDiscount: createRadioFilter(
      'has_discount',
      defaults.hasDiscount,
      'hasDiscount',
      'filters/has-dicount-label'
    ),
    orderCancelled: createRadioFilter(
      'is_cancelled',
      defaults.orderCancelled,
      'orderCancelled',
      'filters/order-cancelled-label'
    ),
    creditNoteWasSent: createRadioFilter(
      'credit_note_was_sent',
      defaults.creditNoteWasSent,
      'creditNoteWasSent',
      'filters/credit-note-sent-label'
    ),
    isCustomerBilled: createRadioFilter(
      'is_customer_billed',
      defaults.isCustomerBilled,
      'isCustomerBilled',
      'filters/customer-billed-label'
    ),
    isCarrierBilled: createRadioFilter(
      'is_carrier_billed',
      defaults.isCarrierBilled,
      'isCarrierBilled',
      'filters/carrier-billed-label'
    ),
    isEligibleForAutomaticCreditNotes: createRadioFilter(
      'is_eligible_for_automatic_credit_notes',
      defaults.isEligibleForAutomaticCreditNotes,
      'isEligibleForAutomaticCreditNotes',
      'Orders eligible for automatic credit notes'
    ),
    hasCarrierInvoices: createRadioFilter(
      'has_carrier_invoices',
      defaults.hasCarrierInvoices,
      'hasCarrierInvoices',
      'filters/has-carrier-invoice-label'
    ),
    hasMissingDocumentRequests: createRadioFilter(
      'has_missing_document_requests',
      defaults.hasMissingDocumentRequests,
      'hasMissingDocumentRequests',
      'filters/has-requested-missing-document-label'
    ),
    openOffers: createRadioFilter(
      'open_offers',
      defaults.openOffers,
      'openOffers',
      'filters/open-offers-label'
    ),

    hasEmptyCapacity: createRadioFilter(
      'has_empty_capacity',
      defaults.hasEmptyCapacity,
      'hasEmptyCapacity',
      'filters/has-empty-capacity-label'
    ),
    recommmendedForUser: createRadioFilter(
      'is_recommended_for_user',
      defaults.recommmendedForUser,
      'recommmendedForUser',
      'filters/recommended-order-label'
    )
  }
})
