var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && _vm.visibleState === 'NEW')?_c('InfoCard',{attrs:{"title":"","img-type":"info"}},[_c('span',[_vm._v(" If you see this text, please wait for 30 seconds and refresh the page. If you still see this message, please place a request for a "),_c('dsf-link',{staticClass:"ordering-root__link",attrs:{"dsf_href":"https://support.sennder.com/support/catalog/items/468","dsf_target":"_blank","dsf_no_underline":""}},[_vm._v(" Shipment Draft / Registration issue ")])],1)]):(!_vm.loading && _vm.order && _vm.order.id)?_c('div',{key:_vm.componentKey,class:{
    'ordering-root': true,
    'ordering-root--full-screen': _vm.enableFullScreen
  }},[(_vm.transportOfferId)?_c('div',[_c('BiddingMicrofrontendLoader',{attrs:{"orderId":_vm.order.id,"transportOfferId":_vm.transportOfferId,"shipmentId":_vm.shipmentId}})],1):_vm._e(),_c('AssignmentsButtonLoader',{attrs:{"selectedIds":_vm.selectedIds,"isAssignmentsManagementEnabled":_vm.isAssignmentsManagementEnabled,"order":_vm.order,"hasBeenCarrierLocked":_vm.hasBeenCarrierLocked,"shipmentId":_vm.shipmentId},on:{"onSubmit":_vm.onSubmit}}),(_vm.isNewV3OrderHeaderEnabled && _vm.isShipmentLinkedToOrder)?_c('OrderInformationHeaderV3',{attrs:{"order":_vm.order,"stops":_vm.orderStops,"subContractingPolicy":_vm.subContractingPolicy,"routeSummary":_vm.routeSummary,"referenceNumber":_vm.computedOrderFromShipmentV3.referenceNumber,"trackingIdForShipper":_vm.computedOrderFromShipmentV3.trackingIdForShipper,"regularity":_vm.computedOrderFromShipmentV3.regularity,"shipperSensitivity":_vm.shipperSensitivity,"shipmentId":_vm.shipmentId,"shipperSalesforceId":_vm.shipperSalesforceId}}):_c('OrderInformationHeader',{attrs:{"order":_vm.order,"stops":_vm.orderStops,"subContractingPolicy":_vm.subContractingPolicy,"shipperSensitivity":_vm.shipperSensitivity,"shipmentId":_vm.shipmentId}}),_c('div',{staticClass:"content-wrapper"},[(
        _vm.isShipmentLinkedToOrder && _vm.visibleState !== 'NEW' && _vm.visibleState !== 'PLANNING'
      )?_c('OrderSidebarV3',{key:"1",staticClass:"sidebar-content",attrs:{"loading":!_vm.isShipmentStopsLoaded,"order":_vm.computedOrderFromShipmentV3,"stops":_vm.shipmentStops,"totalDistance":_vm.totalDistance}}):(
        _vm.visibleState !== 'NEW' &&
        _vm.visibleState !== 'PLANNING' &&
        _vm.orderStops &&
        _vm.orderStops.length
      )?_c('OrderSidebar',{key:"1",staticClass:"sidebar-content",attrs:{"order":_vm.order,"stops":_vm.orderStops}}):_vm._e(),_c('OrderStageContent',{attrs:{"order":_vm.order,"stops":_vm.orderStops,"accountManager":_vm.accountManager,"juniorAccountManager":_vm.juniorAccountManager,"partnerManager":_vm.partnerManager,"spotBidder":_vm.spotBidder},on:{"update-order-regularity":_vm.onUpdateOrderRegularity}})],1),_c('OrderActivitySidebar',{attrs:{"orderId":_vm.order.id,"currentUserId":_vm.currentUserId,"stops":_vm.orderStops}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }