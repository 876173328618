











import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'

import OrderingRoot from './OrderingRoot.vue'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import { FinanceOrderPage } from '@sennder/finance-frontend'
import { isPosteTheme } from '@/controllers/environment-detection'
import { SENN_TMS_URL } from '@/config'
import { useOrchestratorStore } from '@/new-store/orchestrator'

export default defineComponent({
  name: 'OrderDetails',
  components: { OrderingRoot, FinanceOrderPage },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const redirectSolved = ref(false)

    /**
     * We need this to keep compatibility with the Finance Details page.
     * It will be removed by the end of November 2024.
     */
    const orderDetailsComponent = computed(() => {
      return ['financeOrder', 'financeOrderView'].includes(route.name as string)
        ? FinanceOrderPage
        : OrderingRoot
    })

    const componentKey = ref(0)

    const orchestratorStore = useOrchestratorStore()
    const { isActive } = useFeatureFlag()

    onMounted(async () => {
      // Continue loading as usual if the feature flag is not active
      if (!isActive('ENABLE_REDIRECT-TO-ARCHIVE').value) {
        redirectSolved.value = true
        return
      }

      const externalId = `${route.params.orderGroupId}${route.params.letter}`

      // All poste orders are archived
      if (isPosteTheme) {
        window.location.href = `${SENN_TMS_URL}/archive/${externalId}`
      }

      await orchestratorStore.fetchInitialStates(
        externalId,
        isActive('ENABLE_ORDER-DETAILS-NEW-WORLD').value
      )

      // If we detect that the order is not supported in the new world, redirect users to the Archive page.
      if (orchestratorStore.isArchived) router.push(`/archive/${externalId}`)

      redirectSolved.value = true
    })

    return { orderDetailsComponent, componentKey, redirectSolved }
  },
  watch: {
    $route() {
      this.componentKey++
    }
  }
})
