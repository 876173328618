import { isSennderTheme } from '@/controllers/environment-detection'

const CANCELLATION_CONDITIONS: { state: string; groups: string[] }[] = [
  {
    state: 'NEW',
    groups: ['AccountManager', 'DirectDispatcher']
  },
  {
    state: 'REGISTERED',
    groups: [
      'AccountManager',
      'PartnerManager',
      'DirectDispatcher',
      'Finance',
      'FinanceAccountsReceivable'
    ]
  },
  {
    state: 'CARRIER_LOCKED',
    groups: [
      'AccountManager',
      'PartnerManager',
      'DirectDispatcher',
      'Finance',
      'FinanceAccountsReceivable'
    ]
  },
  {
    state: 'DISPATCHED',
    groups: [
      'AccountManager',
      'PartnerManager',
      'DirectDispatcher',
      'Finance',
      'FinanceAccountsReceivable'
    ]
  },
  { state: 'EXECUTED', groups: ['AccountManager', 'PartnerManager', 'DirectDispatcher'] },
  { state: 'OPERATIONS_COMPLETED', groups: ['Finance', 'FinanceAccountsReceivable'] }
]

const EDIT_CUSTOMER_INFO_CONDITIONS = [
  {
    state: 'NEW',
    groups: ['AccountManager', 'DirectDispatcher']
  },
  {
    state: 'REGISTERED',
    groups: ['AccountManager', 'DirectDispatcher']
  },
  {
    state: 'CARRIER_LOCKED',
    groups: ['AccountManager', 'DirectDispatcher']
  },
  {
    state: 'DISPATCHED',
    groups: ['AccountManager', 'DirectDispatcher']
  },
  {
    state: 'EXECUTED',
    groups: ['AccountManager', 'DirectDispatcher']
  },
  {
    state: 'OPERATIONS_COMPLETED',
    groups: ['AccountManager', 'DirectDispatcher']
  }
]

export const canCancelOrder = canAccessOrderOperation(
  CANCELLATION_CONDITIONS,
  isSennderTheme
)

export const canEditCustomerDetails = canAccessOrderOperation(
  EDIT_CUSTOMER_INFO_CONDITIONS
)

export const canCopyOrder = userGroups => !userGroups.includes('CallCenter')

function canAccessOrderOperation(permissionMatrix, isSennderTheme = false) {
  return (orderState, userGroups) => {
    if (isSennderTheme) {
      permissionMatrix.forEach(condition => {
        condition.groups = condition.groups.filter(group => group !== 'PartnerManager')
      })
    }
    return permissionMatrix.some(
      condition =>
        condition.state === orderState && intersects(condition.groups, userGroups)
    )
  }
}

export function intersects(a, b) {
  for (let i = 0; i < a.length; i++) {
    for (let j = 0; j < b.length; j++) {
      if (a[i] === b[j]) return true
    }
  }
  return false
}
