














import { PropType, defineComponent } from '@vue/composition-api'
import { OrderDetail } from '@/services'
import CargoSpecifications from './CargoSpecifications.vue'

export default defineComponent({
  components: { CargoSpecifications },
  props: {
    order: { type: Object as PropType<OrderDetail>, required: true }
  },
  setup(props) {
    return {
      cargoSpecifications: [
        { label: 'Qty', value: props.order.loadQuantity, measuringUnitLabel: '' },
        { label: 'Type', value: props.order.loadUnitType, measuringUnitLabel: '' },
        { label: 'Weight', value: props.order.loadWeight, measuringUnitLabel: 't' },
        { label: 'Height', value: props.order.loadHeight, measuringUnitLabel: 'cm' },
        { label: 'Length', value: props.order.loadLength, measuringUnitLabel: 'cm' },
        { label: 'Width', value: props.order.loadWidth, measuringUnitLabel: 'cm' }
      ]
    }
  }
})
