import axios, { AxiosError } from 'axios'
import { getAuth } from '../../../core-config/orchestrated/auth'

const genericClient = axios.create()

genericClient.interceptors.request.use(
  async function (config) {
    const commonHeaders = await getAuth().getCommonHeaders()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers = {
      ...config.headers,
      ...commonHeaders
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

genericClient.interceptors.response.use(
  function (response) {
    return response
  },
  function (error: AxiosError) {
    return Promise.reject(error)
  }
)

export { genericClient }
