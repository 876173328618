/* eslint-disable no-console */
import { INotifications } from '@sennder/senn-node-microfrontend-interfaces'

const defaultNotifications: INotifications = {
  error(message: string) {
    console.error('notifications.error:', message)
  },
  success(message: string) {
    console.log('notifications.success:', message)
  }
}

let notifications: INotifications = defaultNotifications

export const setNotifications = (n: INotifications) => {
  notifications = n
}

export { notifications as default }
