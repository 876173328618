import { ref, Ref } from '@vue/composition-api'
import { fetchOrderPaymentInformation } from '@/modules/ordering/legacy/services/finance-service'
import { OrderPaymentInformation } from '@/modules/ordering/legacy/services/types'

const paymentInfo: Ref<OrderPaymentInformation> = ref(null)
export default (orderId: Ref<number>) => {
  const fetchPaymentInformation = async (): Promise<void> => {
    paymentInfo.value = await fetchOrderPaymentInformation(orderId.value)
  }

  return {
    paymentInfo,
    fetchPaymentInformation
  }
}
