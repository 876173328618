import * as USER_GROUPS from '../user-groups'

export const currentUser = state => state.currentUser

export const isLoggedIn = state => state.currentUser.id !== null

export const currentUserHasAdminLevel = state => currentUserLevel(state) === 'ADMIN'

export const currentUserHasTeamLeadLevel = state =>
  currentUserLevel(state) === 'TEAM_LEAD'

function currentUserLevel(state) {
  return state.currentUser.level
}

export const currentUserEmail = state => state.currentUser.email

export const currentUserId = state => state.currentUser.id

export const currentUserGroups = state => state.currentUser.group_names

export const doesUserHavePermission = state => permission =>
  state.currentUser.permissions.includes(permission)

const USER_GROUPS_ALLOWED_TO_LOGIN = [
  USER_GROUPS.STAFF,
  USER_GROUPS.FINANCE,
  USER_GROUPS.CALL_CENTER,
  USER_GROUPS.ENGINEERING,
  USER_GROUPS.PRODUCT_OWNER,
  USER_GROUPS.ACCOUNT_MANAGERS,
  USER_GROUPS.PARTNER_MANAGERS
]

export const currentUserCanLogin = (state, getters) =>
  getters.currentUserGroups.some(e => USER_GROUPS_ALLOWED_TO_LOGIN.includes(e))

export const currentUserIsCallCenter = (state, getters) =>
  getters.currentUserGroups.includes(USER_GROUPS.CALL_CENTER)

export const currentUserIsInFinanceGroup = (state, getters) =>
  getters.currentUserGroups.includes(USER_GROUPS.FINANCE)

export const currentUserIsFinanceAR = (state, getters) =>
  getters.currentUserGroups.includes(USER_GROUPS.FINANCE_AR)

export const currentUserIsStaff = (state, getters) =>
  getters.currentUserGroups.includes(USER_GROUPS.STAFF)

export const currentUserIsFounder = (state, getters) =>
  getters.currentUserGroups.includes(USER_GROUPS.FOUNDER)

export const currentUserIsInEngineering = (state, getters) =>
  getters.currentUserGroups.includes(USER_GROUPS.ENGINEERING)

export const currentUserIsProductOwner = (state, getters) =>
  getters.currentUserGroups.includes(USER_GROUPS.PRODUCT_OWNER)

export const currentUserIsAccountManager = (state, getters) =>
  getters.currentUserGroups.includes(USER_GROUPS.ACCOUNT_MANAGERS)

export const currentUserIsPartnerManager = (state, getters) =>
  getters.currentUserGroups.includes(USER_GROUPS.PARTNER_MANAGERS)

export const currentUserIsPartnerManagementAdmin = (state, getters) =>
  getters.currentUserGroups.includes(USER_GROUPS.PARTNER_MANAGEMENT_ADMINS)

export const currentUserIsPMTeamAssigner = (state, getters) =>
  getters.currentUserGroups.includes(USER_GROUPS.PARTNER_MANAGERS_TEAM_ASSIGNER)

export const currentUserIsPmaReadOnlyUser = (state, getters) =>
  getters.currentUserGroups.includes(USER_GROUPS.PMA_READ_ONLY_USER)

export const currentUserIsDirectDispatcher = (state, getters) =>
  getters.currentUserGroups.includes(USER_GROUPS.DIRECT_DISPATCHER)

export const currentUserIsOps = (state, getters) =>
  getters.currentUserIsStaff &&
  (getters.currentUserIsAccountManager || getters.currentUserIsPartnerManager)

export const canDispatchAndUndispatchOrder = (state, getters) =>
  getters.currentUserGroups.includes(USER_GROUPS.PARTNER_MANAGERS) ||
  getters.currentUserGroups.includes(USER_GROUPS.ACCOUNT_MANAGERS)

export const canUseNewUpload = (state, getters) =>
  getters.currentUserGroups.includes(USER_GROUPS.NEW_CSV_UPLOAD)

export const canUseGeneralCsvUpload = (state, getters) =>
  !canUseNewUpload(state, getters) &&
  (getters.currentUserGroups.includes(USER_GROUPS.CSV_UPLOAD) ||
    getters.currentUserGroups.includes(USER_GROUPS.ENGINEERING) ||
    getters.currentUserGroups.includes(USER_GROUPS.PRODUCT_OWNER))

export const canUseOrderTemplateGeneration = (state, getters) =>
  getters.currentUserGroups.includes(USER_GROUPS.ORDER_TEMPLATE) ||
  getters.currentUserGroups.includes(USER_GROUPS.ENGINEERING) ||
  getters.currentUserGroups.includes(USER_GROUPS.PRODUCT_OWNER)

export const canUserAccessFinanceSection = (state, getters) =>
  getters.currentUserIsFounder ||
  getters.currentUserIsInFinanceGroup ||
  getters.currentUserIsProductOwner ||
  getters.currentUserIsInEngineering

export const canUseCarrierTimesFeature = (state, getters) =>
  getters.currentUserIsInEngineering || getters.currentUserIsProductOwner

export const currentUserIsFinanceOnly = (state, getters) =>
  (getters.currentUserIsInFinanceGroup || getters.currentUserIsFinanceAR) &&
  !getters.currentUserIsOps
