import {
  COST_CENTRE_ENABLED,
  COST_CENTRE_URL,
  DOCUMENT_UPLOAD_ENABLED,
  EXCHANGE_RATES_API_URL,
  FLEET_SERVICE_URL,
  OPERATIONS_BACKEND_URL,
  OWN_CARRIER_ASSET_IDS,
  SHIPPER_INVOICES_UPLOAD_ENABLED,
  USER_MANAGEMENT_API_URL,
  POD_UPLOAD_SERVICE_URL,
  CLAIMS_API_URL,
  MICRO_FRONTEND_MODE,
  CARRIER_CONTRACT_API_URL
} from '@/config'
import * as orderingService from '@/services/ordering-service'
import Vue from 'vue'
import PortalVue from 'portal-vue'
import {
  getAppTheme,
  isProductionUrl,
  isSennderTheme
} from '@/controllers/environment-detection'

import IncidentManagement, {
  setOptions as setIncidentManagementOptions,
  Options as IncidentManagementOptions
} from '@sennder/incident-management'
import ContractManagement from '@sennder/contract-management'
import useCurrentUser from '@/compositions/useCurrentUser'
import useOrder from '@/compositions/useOrder'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import {
  refreshOrderLogs,
  toggleSidebar,
  expandSidebar,
  refreshOrderIncidents,
  sidebarIsVisible,
  OrderActivitySidebar
} from '@/modules/order-activity-sidebar'
import { useSteps, OrderStateChip, OrderStageFooter } from '@/modules/ordering'
import { i18n } from '@/plugins/i18n'
import FinanceFrontend from '@sennder/finance-frontend'
import RenderWithLaunchDarkly from '@/components/RenderWithLaunchDarkly.vue'
import { getAuthHeader } from '@/services/auth-service'
import { getCommonHeaders } from '@/services/auth-service'

const getAuthHeaderAdapter = async (audience: string, scope?: string) => {
  return getAuthHeader({
    audience,
    scope
  })
}

const getIncidentManagementParams = (authHeader?: string): IncidentManagementOptions => {
  const { isActive } = useFeatureFlag()
  const useShipmentIncidentFeatureFlag = isActive('use-shipment-incident-mgmt').value
  const useFacilityActionsFeatureFlag = isActive('transit-facility-actions').value

  return {
    baseURL: process.env.VUE_APP_INCIDENT_MANAGEMENT_API_URL,
    baseURLAuth0: process.env.VUE_APP_INCIDENT_MANAGEMENT_API_URL + '/auth0',
    authHost: OPERATIONS_BACKEND_URL,
    apiErrorHandler: (error: Error) => {
      throw error
    },
    getAuthorizationHeaderWithParams: getAuthHeaderAdapter,
    useShipmentIncidentFeatureFlag,
    useFacilityActionsFeatureFlag,
    authHeader
  }
}

const getContractManagementParams = () => ({
  baseURL: process.env.VUE_APP_CONTRACT_MANAGEMENT_API_URL,
  mothershipURL: OPERATIONS_BACKEND_URL,
  userManagementURL: USER_MANAGEMENT_API_URL,
  getAuthorizationHeaderWithParams: getAuthHeaderAdapter,
  options: { useCurrentUser, isMicrofrontend: MICRO_FRONTEND_MODE },
  carrierContractServiceURL: CARRIER_CONTRACT_API_URL
})

const getFinanceParams = (authHeader?: string) => ({
  baseURL: OPERATIONS_BACKEND_URL,
  claimsUrl: CLAIMS_API_URL,
  getCommonHeaders,
  costCentreEnabled: COST_CENTRE_ENABLED,
  documentUploadEnabled: DOCUMENT_UPLOAD_ENABLED,
  shipperInvoicesUploadEnabled: SHIPPER_INVOICES_UPLOAD_ENABLED,
  costCentreURL: COST_CENTRE_URL,
  fleetServiceURL: FLEET_SERVICE_URL,
  exchangeServiceURL: EXCHANGE_RATES_API_URL,
  podUploadServiceURL: POD_UPLOAD_SERVICE_URL,
  getAuthorizationHeaderWithParams: getAuthHeaderAdapter,
  ownCarrierAssetIds: OWN_CARRIER_ASSET_IDS,
  tenant: getAppTheme(),
  authorizationServiceURL: OPERATIONS_BACKEND_URL,
  i18n,
  isSennderTheme,
  useCurrentUser,
  useOrder,
  useFeatureFlag,
  // refreshOrderLogs is initialized lazily: onBeforeMount.
  // The Anonymous function allows using the function after initialization.
  refreshOrderLogs: () => refreshOrderLogs(),
  orderingService,
  libraries: {
    useSteps,
    useOrderActivitySidebar: () => ({
      toggleSidebar,
      expandSidebar,
      refreshOrderLogs: () => refreshOrderLogs(),
      refreshOrderIncidents,
      sidebarIsVisible
    })
  },
  components: {
    OrderStateChip,
    OrderActivitySidebar,
    OrderStageFooter,
    RenderWithLaunchDarkly
  },
  incidentManagementParams: getIncidentManagementParams(authHeader),
  isProduction: isProductionUrl(),
  isMicrofrontend: MICRO_FRONTEND_MODE
})

/**
 *
 * @desc Updates the API of micro frontends based on launch darkly config
 */
export const updateMicroFrontendsApi = (): void => {
  setIncidentManagementOptions(getIncidentManagementParams())
}

/**
 *
 * @desc Alias of updateMicroFrontendsApi using the Vue plugins API and containing micro-frontends those don't expose setOptions method
 */

export const initMicroFrontends = (authHeader?: string): void => {
  const incidentManagementParams = getIncidentManagementParams(authHeader)

  Vue.use(IncidentManagement, incidentManagementParams)
  Vue.use(ContractManagement, getContractManagementParams())
  Vue.use(FinanceFrontend, getFinanceParams(authHeader))
  Vue.use(PortalVue)
}
