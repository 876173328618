import { computed, ref } from '@vue/composition-api'
import { defineStore } from 'pinia'
import { useCarrierStore } from './carrier'
import { useFacilityManagementStore } from './facility-management'
import { useMatchingStore } from './matching'
import { useOperatorsStore } from './operators'
import { useSettlementStore } from './settlement'
import { useShipmentStore } from './shipment'
import { useShipperStore } from './shipper'
import { useTransitStore } from './transit'
import { useTransportPlanningStore } from './transport-planning'
import { monitorError } from '@/analytics/monitoring'

export const useOrchestratorStore = defineStore('orchestrator-store', () => {
  // setting nested stores
  const shipmentStore = useShipmentStore()
  const matchingStore = useMatchingStore()
  const transportPlanningStore = useTransportPlanningStore()
  const transitStore = useTransitStore()
  const settlementStore = useSettlementStore()
  const operatorsStore = useOperatorsStore()
  const carrierStore = useCarrierStore()
  const shipperStore = useShipperStore()
  const facilityManagerStore = useFacilityManagementStore()

  // state
  const loaders = ref<string[]>([])
  const isArchived = ref<boolean>(false)

  // getters
  const isLoading = computed<boolean>(() => loaders.value.length > 0)

  // actions
  /**
   * @description Fetches all data, that we expect to have before we have a carrier locked:
   * - shipment routes
   * - shipper
   * - shipper price
   * - facility plan
   * - transport offer with matches
   * - facility profiles
   * @param shipmentId
   */
  const fetchPreMatchStates = async () => {
    try {
      loaders.value.push('pre-match')

      if (!shipmentStore.shipment) throw new Error('Shipment is not loaded')

      await Promise.all([
        shipmentStore.fetchShipmentRoutes(shipmentStore.shipment.id),
        shipperStore.fetchShipper(shipmentStore.shipment.shipper.profile_id),
        facilityManagerStore.fetchFacilityPlan(shipmentStore.shipment.id),
        matchingStore.fetchTransportOfferWithMatches(shipmentStore.shipment.id),
        settlementStore.fetchShipperPrice(shipmentStore.shipment.external_id)
      ])

      await facilityManagerStore.fetchFacilityProfiles(
        facilityManagerStore.facilityPlan?.stops.map(stop => stop.facility_profile_id) ||
          []
      )
    } catch (err) {
      monitorError(new Error('Failed to fetch pre-match states'), 'octopus-new-store', {
        err
      })
      throw err
    } finally {
      loaders.value = loaders.value.filter(loader => loader !== 'pre-match')
    }
  }

  /**
   * @description Fetches all data, that we expect to have after we have a carrier locked:
   * - transport plan with details with assets
   * - tour execution
   * - carrier cost
   * - carrier
   * - carrier contact
   */
  const fetchPostMatchStates = async () => {
    try {
      loaders.value.push('post-match')

      if (!shipmentStore.shipment) throw new Error('Shipment is not loaded')

      if (!matchingStore.match) throw new Error('Match is not created')

      await Promise.all([
        transportPlanningStore.fetchTransportPlanWithDetailsWithAssets(
          shipmentStore.shipment.external_id,
          matchingStore.match.carrier_id
        ),
        settlementStore.fetchCarrierCost(shipmentStore.shipment.external_id),
        carrierStore.fetchCarrier(matchingStore.match.carrier_id),
        carrierStore.fetchCarrierContact(matchingStore.match.carrier_contact_id)
      ])

      if (transportPlanningStore.isPlanConfirmed) {
        await transitStore.fetchTourExecution(shipmentStore.shipment.id)
      }
    } catch (err) {
      monitorError(new Error('Failed to fetch post-match states'), 'octopus-new-store', {
        err
      })
      throw err
    } finally {
      loaders.value = loaders.value.filter(loader => loader !== 'post-match')
    }
  }

  const fetchInitialStates = async (externalId: string, loadAll = false) => {
    try {
      // we start with the shipment, currently it's the only entry point to the Details page
      loaders.value.push('shipment')

      await shipmentStore.fetchShipment(externalId)

      loaders.value = loaders.value.filter(loader => loader !== 'shipment')

      // check the shipment status, raise Archive flag and stop the flow if the shipment is archived or doesn't exist
      if (!shipmentStore.shipment || shipmentStore.isPreRampUp) {
        isArchived.value = true
        return
      }

      // Don't load data after shipment if we don't need it
      if (!loadAll) return

      // fetch the data that we need before we have a carrier locked
      await fetchPreMatchStates()

      // if we're matched already, we need to fetch the data that we need after we have a carrier locked
      if (matchingStore.isCFTLMatched || matchingStore.isCharteringMatched) {
        await fetchPostMatchStates()
      }

      // fetching operators in the end when we know shipment and transfer office ids
      loaders.value.push('operators')
      await operatorsStore.fetchAssignmentsWithOperators({
        shipmentId: shipmentStore.shipment.id,
        transportOfferId: matchingStore.transportOffer?.id
      })
      loaders.value = loaders.value.filter(loader => loader !== 'operators')
    } catch (err) {
      monitorError(new Error('Failed to fetch initial states'), 'octopus-new-store', {
        err
      })

      // todo: right now we want to stop the error propagation, but we need to handle it properly when we start relying on the new store
      // throw err
    } finally {
      loaders.value = []
    }
  }

  return {
    loaders,
    isLoading,
    isArchived,
    fetchPreMatchStates,
    fetchPostMatchStates,
    fetchInitialStates
  }
})
