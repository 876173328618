

















import { defineComponent, PropType } from '@vue/composition-api'
import BillingInfo from './BillingInfo.vue'
import { OrderPaymentInformation } from '@/modules/ordering/legacy/services/types'
import OctopusPODReviewWidget from '@/microfrontends/widgets/pod-review-widget/PODReviewWidget.vue'

export default defineComponent({
  components: { BillingInfo, OctopusPODReviewWidget },
  props: {
    orderId: { type: Number, required: true },
    carrierId: { type: Number, required: true },
    paymentInfo: { type: Object as PropType<OrderPaymentInformation>, required: true },
    externalId: { type: String, required: true },
    shipmentId: { type: String, default: '' }
  }
})
