import { monitorError } from '@/analytics/monitoring'
import {
  getShipmentByExternalId,
  getShipmentRoutes
} from '@/api/services/shipment.service'
import { Shipment, ShipmentRoute } from '@/types/shipment'
import { computed, ref } from '@vue/composition-api'
import { AxiosError } from 'axios'
import { getUnixTime } from 'date-fns'
import { defineStore } from 'pinia'

// We consider all shipments created before 01.11.2023 as archived
const ARCHIVED_ORDERS_CUTOFF_UNIX_TS = 1698879599

export const useShipmentStore = defineStore('shipment-store', () => {
  // state
  const loaders = ref<string[]>([])
  const shipment = ref<Shipment | null>(null)
  const shipmentRoutes = ref<ShipmentRoute[] | null>(null)

  // getters
  const isLoading = computed<boolean>(() => loaders.value.length > 0)
  const isPreRampUp = computed<boolean>(() => {
    return shipment.value
      ? getUnixTime(new Date(shipment.value.context.created_at)) <
          ARCHIVED_ORDERS_CUTOFF_UNIX_TS
      : true
  })

  // actions
  async function fetchShipment(externalId: string, showLoader = true) {
    try {
      if (showLoader) loaders.value.push('shipment')
      shipment.value = await getShipmentByExternalId(externalId)
    } catch (err) {
      if ((err as AxiosError).response?.status === 404) {
        // shipment doesn't exist, the order is most likely archived
        return
      }

      monitorError(new Error('Failed to fetch shipment'), 'octopus-new-store', { err })
      throw err
    } finally {
      loaders.value = loaders.value.filter(loader => loader !== 'shipment')
    }
  }

  async function fetchShipmentRoutes(shipmentId: string, showLoader = true) {
    try {
      if (showLoader) loaders.value.push('shipmentRoutes')
      shipmentRoutes.value = await getShipmentRoutes(shipmentId)
    } catch (err) {
      monitorError(new Error('Failed to fetch shipment routes'), 'octopus-new-store', {
        err
      })
      throw err
    } finally {
      loaders.value = loaders.value.filter(loader => loader !== 'shipmentRoutes')
    }
  }

  return {
    loaders,
    isLoading,
    isPreRampUp,
    shipment,
    shipmentRoutes,
    fetchShipment,
    fetchShipmentRoutes
  }
})
