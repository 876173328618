export const createInitialState = () => {
  return {
    accountManagerTransportOffers: {},
    accountManagerTransportOfferUpdates: {},
    addresses: {},
    carrier_extra_charges: {},
    carrier_invoices: {},
    carriers: {},
    charge_corrections: {},
    charges: {},
    company_addresses: {},
    contacts: {},
    customer_extra_charges: {},
    customers: {},
    filtersCollapsed: true,
    issues: {},
    loadingPage: false,
    locations: {},
    logs: {},
    marketplace_loads: {},
    notes: {},
    order_assignments: {},
    order_groups: {},
    orders_aggregation: {},
    orders: {},
    paymentReadinessDetails: {
      billingDetailsAreFilled: false,
      documentsAreReady: false
    },
    partnerManagerTransportOffers: {},
    partnerManagerTransportOfferUpdates: {},
    persons: {},
    steps: {},
    temporary_drivers: {},
    trackers: {},
    transfers: {},
    transport_requests: {},
    users: {},
    proofOfDeliveryDocuments: {},
    proofOfDeliveryRejectionReasons: [],
    carrierSearchResults: [],
    driverSearchOptions: [],
    orderDriver: null
  }
}
