


















































































import OrderStageFooter from '@/modules/ordering/components/shared/OrderStageFooter.vue'
import SendDispatchConfirmationModal from '@/components/SendDispatchConfirmationModal.vue'
import CostForm from './dispatching-form/CostForm.vue'
import {
  TrackingConfig,
  DispatchingTransferDetail,
  DispatchingOrderDetail
} from '@/modules/ordering/components/dispatching/types'
import { hasHadState } from '@/modules/common/order-states'
import {
  defineComponent,
  defineAsyncComponent,
  computed,
  ref,
  watch,
  PropType,
  onMounted,
  onUnmounted
} from '@vue/composition-api'
import { VehicleType } from '@/services'
import useCurrentUser from '@/compositions/useCurrentUser'
import useStore from '@/compositions/useStore'
import useDispatchingFormValidation from '../../compositions/useDispatchingFormValidation'
import useDispatchingFormValues from '../../compositions/useDispatchingFormValues'
import { ExtendedPerson } from '@/compositions/useOrder'
import * as AllocationService from '@/microfrontends/widgets/fleet-asset-management-widget/AllocationService'
import {
  MicrofrontendEvents,
  subscribe
} from '@sennder/senn-node-microfrontend-event-bus'
import { getPollOrderState } from '@/modules/ordering/compositions/pollOrderState'
import { getLogger } from '@/shell/datadog-logger'
import notifications from '../../../../../core-config/orchestrated/notifications'

export default defineComponent({
  components: {
    FleetAssetManagementWidget: defineAsyncComponent(
      () =>
        import(
          /* webpackPreload: true */
          '@/microfrontends/widgets/fleet-asset-management-widget/FleetAssetManagementWidget.vue'
        )
    ),
    OrderStageFooter,
    CostForm,
    SendDispatchConfirmationModal
  },
  props: {
    stops: { type: Array, default: () => [] },
    order: { type: Object as PropType<DispatchingOrderDetail>, required: true },
    accountManager: { type: Object as PropType<ExtendedPerson>, default: null },
    juniorAccountManager: { type: Object as PropType<ExtendedPerson>, default: null },
    partnerManager: { type: Object as PropType<ExtendedPerson>, default: null },
    spotBidder: { type: Object as PropType<ExtendedPerson>, default: null }
  },
  setup(props) {
    const store = useStore()
    const wrapper = ref<HTMLDivElement | null>(null)
    const dispatchConfirmationModalShown = ref<boolean>(false)
    const eventsAbortController = new AbortController()
    const stopOrderStatePooling = ref<() => void>(() => {})

    const onWhenDispatched = async () => {
      const logger = getLogger()
      logger.info('[TransportPlanning] refresh order enabled', {})
      if (
        props.order.state == undefined ||
        !hasHadState(props.order.state, 'DISPATCHED')
      ) {
        await store.dispatch('ordering/onOrderDispatched')
        await notifications.success(
          'You do not have to dispatch anymore. Click on the execution tab or refresh the page to see the execution tab.'
        )
      }
    }

    const { pollOrderState } = getPollOrderState(
      props.order.id,
      props.order.state,
      'DISPATCHED',
      onWhenDispatched,
      stopOrderStatePooling
    )

    // subscribe to the onTransportPlanConfirmed
    subscribe<MicrofrontendEvents.onTransportPlanConfirmed>(
      MicrofrontendEvents.onTransportPlanConfirmed,
      async () => {
        pollOrderState()
      },
      {
        signal: eventsAbortController.signal
      }
    )

    const { canDispatchAndUndispatchOrder } = useCurrentUser()
    const lastAllocationSave = ref<Date>(new Date())

    const trackingConfig = computed<TrackingConfig>(
      () => store.state.ordering.trackingConfig
    )

    const transfer = computed<DispatchingTransferDetail>(
      () => store.state.ordering.transfer
    )

    const allocationInformation = ref<{
      carrierId: number
      allocations: AllocationService.Allocation[]
    } | null>(null)

    const { formValues, usesDriverApp } = useDispatchingFormValues(transfer)

    const { costForm, validateForm } = useDispatchingFormValidation(wrapper)

    const disabled = computed<boolean>(
      () => props.order.state !== 'CARRIER_LOCKED' || !canDispatchAndUndispatchOrder.value
    )

    const initializeVehicleType = (val: VehicleType): void => {
      if (!formValues.dispatchedVehicle) formValues.vehicleType = val
    }

    const closeDispatchConfirmationModal = (): void => {
      dispatchConfirmationModalShown.value = false
    }

    const updateTracking = (value: { gatehouse: boolean; amazon: boolean }) => {
      formValues.gatehousePushOn = value.gatehouse
      formValues.amazonPushOn = value.amazon
    }

    const shouldRenderFleetWidget = computed<boolean>(() =>
      Boolean(props.order.carrierCompany && props.order.carrierCompany.id)
    )

    const sendDispatchConfirmation = async (): Promise<null> => {
      dispatchConfirmationModalShown.value = true
      return null
    }

    watch(
      transfer,
      () => {
        if (!transfer.value) return
        formValues.notes = transfer.value.notes
        formValues.vehicleType =
          transfer.value.dispatchedVehicle || props.order.vehicleType
        formValues.transferCode = transfer.value.code
        formValues.driverName = transfer.value.driverName || ''
        formValues.driverPhoneNumber = transfer.value.driverPhoneNumber || ''
        formValues.dispatchedVehicle = transfer.value.dispatchedVehicle
      },
      { immediate: true }
    )

    watch(
      trackingConfig,
      () => {
        if (!trackingConfig.value) return
        formValues.gatehousePullOn = trackingConfig.value.gatehousePullOn
        formValues.gatehousePushOn = trackingConfig.value.gatehousePushOn
        formValues.amazonPushOn = trackingConfig.value.amazonPushOn
      },
      { immediate: true }
    )

    watch(
      () => props.order,
      async () => {
        formValues.allowedVehicleTypes = props.order.allowedVehicleTypes
        formValues.carrierCompany = props.order.carrierCompany
        formValues.carrierContact = props.order.carrierContact || {}
        formValues.baseCost = props.order.baseCost
        formValues.costSurcharge = props.order.preDispatchCarrierExtraChargeAmount
        formValues.costSurchargeDescription =
          props.order.preDispatchCarrierExtraChargeDescription
      },
      { immediate: true }
    )

    watch(
      () => props.partnerManager,
      () => {
        formValues.partnerManager = props.partnerManager
      },
      { immediate: true }
    )

    watch(
      () => formValues.gatehousePushOn,
      currentValue => {
        if (!currentValue) formValues.amazonPushOn = false
      }
    )

    onMounted(() => {
      if (wrapper.value?.scrollIntoView) {
        wrapper.value.scrollIntoView()
      }
    })

    onUnmounted(() => {
      stopOrderStatePooling.value()
      eventsAbortController.abort()
    })

    const onAllocationUpdate = (update: {
      carrierId: number
      allocations: AllocationService.Allocation[]
    }) => {
      allocationInformation.value = update
    }

    return {
      wrapper,
      canDispatchAndUndispatchOrder,
      dispatchConfirmationModalShown,
      disabled,
      initializeVehicleType,
      closeDispatchConfirmationModal,
      //form validation
      costForm,
      validateForm,
      //actions
      sendDispatchConfirmation,
      //form values
      formValues,
      usesDriverApp,
      //widget
      onAllocationUpdate,
      updateTracking,
      lastAllocationSave,
      shouldRenderFleetWidget
    }
  }
})
