import { SINGLE_AUDIENCE } from '@/services/auth-service'
import { OPERATORS_API_URL } from '@/config'
import { runJsonPut, runJsonGet } from '@/models/backend-client'
import logger from '@/shell/console-logger'

export enum ROLES {
  SENIOR_ACCOUNT_MANAGER = 'snr_aom',
  ACCOUNT_MANAGER = 'aom',
  SPOT_BIDDER = 'spot_bidder',
  CARRIER_MANGER = 'cm'
}

interface Operator {
  entity_name: string
  entity_id: string
  operator_id: string
  role: string
}

export const assignOperator = async (
  entityId: string,
  entity: 'shipment' | 'transport_offer' = 'shipment',
  operatorId: string,
  role: ROLES
): Promise<void> => {
  const url = `${OPERATORS_API_URL}/v1/role/${entity}/${entityId}`

  logger.debug(
    `Calling assign operator roles API with PATH: ${url} with ${entity} entity and entity id: ${entityId}`
  )

  await runJsonPut(
    url,
    { operator_id: operatorId, role: role },
    { apiGatewayAuthorization: true },
    SINGLE_AUDIENCE
  )
}

export const getOperators = async (
  entityId: string,
  entity: 'shipment' | 'transport_offer' = 'shipment'
): Promise<{ [p: string]: string } | null> => {
  const url = `${OPERATORS_API_URL}/v1/roles/${entity}/${entityId}`

  logger.debug(
    `Calling fetch operator roles API with PATH: ${url} with ${entity} entity and entity id: ${entityId}`
  )
  try {
    const response = await runJsonGet(
      url,
      {},
      { apiGatewayAuthorization: true },
      SINGLE_AUDIENCE
    )

    if (response && response.status === 200 && response.data.length) {
      return Object.fromEntries(
        response.data.map((operator: Operator) => [operator.role, operator.operator_id])
      )
    }
  } catch (error) {
    logger.warn(
      `Operator role assignment service failed for ${entity}: ${entityId} ${error}`
    )
  }
  return null
}
