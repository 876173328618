
































































import { i18n } from '@/plugins/i18n'
import { defineComponent, ref, computed, watch } from '@vue/composition-api'
import { CANCELLATION_REASONS } from '@/modules/ordering/constants'
import type { CancellationReason, CancellationPayload } from '@/modules/ordering/types'

const MIN_COMMENT_LENGTH = 10

export default defineComponent({
  name: 'ShipmentCancellationModal',

  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },

  setup(props, { emit }) {
    const selectedReason = ref('')
    const comment = ref('')

    const selectedReasonData = computed((): CancellationReason | undefined =>
      CANCELLATION_REASONS.find(reason => reason.id === Number(selectedReason.value))
    )

    const cancellationReasons = computed((): CancellationReason[] => CANCELLATION_REASONS)

    const isValid = computed((): boolean => {
      if (!selectedReason.value) return false

      if (selectedReasonData.value?.requiresComment) {
        const trimmedComment = comment.value?.trim() || ''
        return trimmedComment.length >= MIN_COMMENT_LENGTH
      }

      return true
    })

    const getCommentLabel = computed((): string => {
      const suffix = selectedReasonData.value?.requiresComment
        ? ` * (min. ${MIN_COMMENT_LENGTH} characters)`
        : ' (Optional)'
      return i18n.t('shipment/cancellation-modal/comment-label') + suffix
    })

    const isReasonSelected = (reasonId: number): boolean =>
      Number(selectedReason.value) === reasonId

    const handleReasonSelect = (event: CustomEvent): void => {
      selectedReason.value = event.detail
    }

    const handleCommentChange = (event: CustomEvent): void => {
      comment.value = event.detail
    }

    const validateForm = (): boolean => {
      if (!selectedReason.value) return false

      if (selectedReasonData.value?.requiresComment) {
        const trimmedComment = comment.value?.trim() || ''
        return trimmedComment.length >= MIN_COMMENT_LENGTH
      }

      return true
    }

    const resetForm = (): void => {
      selectedReason.value = ''
      comment.value = ''
    }

    const handleConfirm = (): void => {
      if (!validateForm()) return

      const payload: CancellationPayload = {
        reason: Number(selectedReason.value),
        comment: comment.value?.trim() || ''
      }

      emit('confirm', payload)
    }

    const handleClose = (): void => {
      resetForm()
      emit('close')
    }

    watch(
      () => props.isOpen,
      (newValue: boolean) => {
        if (!newValue) {
          resetForm()
        }
      }
    )

    return {
      selectedReason,
      comment,
      selectedReasonData,
      cancellationReasons,
      isValid,
      getCommentLabel,
      isReasonSelected,
      handleReasonSelect,
      handleCommentChange,
      handleConfirm,
      handleClose
    }
  }
})
