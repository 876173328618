
















































































import { defineComponent, computed, onMounted, ref } from '@vue/composition-api'
import {
  VehicleType,
  AllowedVehicles,
  AllowedVehicleType,
  VEHICLE_TYPE_MAP
} from '@/services'
import { VEHICLE_TYPES } from '@sennder/octopus-constants'
import LabeledText from '@/modules/common/components/LabeledText.vue'
import CountableSpecsLabel from './CountableSpecsLabel.vue'
import { placeholderIfEmpty } from '@/global-setup/filters'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import startCase from 'lodash/startCase'
import { checkCMRole } from '@/modules/dashboard/helpers/orcas-referral-link'
import useCurrentUser from '@/compositions/useCurrentUser'

const COUNTABLE_VEHICLE_SPECS_MAP = {
  sideboards: 'Sideboards',
  cornerEdgeProtectors: 'Corner/Edge Protectors',
  antiSlipMats: 'Anti-Slip Mats',
  strapsBelts: 'Starp & Belts',
  bars: 'Bars',
  chains: 'Chains'
}

const VEHICLE_SPECS_MAP = {
  liftingRoof: 'Lifting Roof',
  tailLift: 'Tail Lift',
  forkLift: 'Forklift',
  trailerExchangeProhibited: 'Trailer Exchange Prohibited',
  doubleDriver: 'Double Driver',
  tirCable: 'TIR Cable',
  sealable: 'Sealable',
  codeXl: 'Code XL',
  cleanliness: 'Cleanliness certificate',
  arrivalNotification: 'Arrival notice',
  directTransfer: 'Direct transfer'
}

export default defineComponent({
  components: { LabeledText, CountableSpecsLabel },
  props: {
    order: { type: Object, required: true }
  },
  setup(props) {
    const { isActive } = useFeatureFlag()
    const { user } = useCurrentUser()
    const fuelTypeMappings: Record<string, string> = {
      diesel: 'Diesel',
      b100: 'B100',
      hvo_direct: 'HVO Direct',
      hvoflex_5: 'HVOFlex 5',
      hvoflex_10: 'HVOFlex 10',
      hvoflex_25: 'HVOFlex 25',
      hvoflex_50: 'HVOFlex 50',
      hvoflex_100: 'HVOFlex 100',
      electric: 'Electric',
      hydrogen: 'Hydrogen'
    }

    const vehicleFuelType = ref('')

    const preferredVehicleType = computed<string>(() => {
      const { preferredVehicleType, loadMinimumTemperature, loadMaximumTemperature } =
        props.order

      if (!preferredVehicleType) return '-'

      const formattedPreferredVehicleType: VehicleType =
        preferredVehicleType.toUpperCase()
      const formattedVehicleType = VEHICLE_TYPES[formattedPreferredVehicleType]

      const hasValidTemperatures =
        typeof loadMinimumTemperature === 'number' &&
        typeof loadMaximumTemperature === 'number'

      const temperatureRange = hasValidTemperatures
        ? ` (min: ${loadMinimumTemperature}°C, max: ${loadMaximumTemperature}°C)`
        : ''

      return formattedVehicleType + temperatureRange
    })

    const isOrderSidebarVehicleFuelTypeEnabled = computed(() => {
      return isActive('ENABLE_ORDER-SIDEBAR-VEHICLE-FUEL-TYPE')?.value
    })

    const allowedVehicles = computed<string | null>(() => {
      const validVehicleTypes = Object.keys(VEHICLE_TYPE_MAP)
      const allowed = props.order.allowedVehicles as AllowedVehicles

      if (!allowed) return null

      const formattedTypes = Object.keys(allowed)
        .filter(type => allowed[type] && validVehicleTypes.includes(type.toUpperCase()))
        .map(type => VEHICLE_TYPE_MAP[type.toUpperCase() as AllowedVehicleType])
        .join(', ')

      return formattedTypes || null
    })

    const adrClasses = computed(() => props.order.adrClasses.join(', '))

    const limitedQuantity = computed(() => (props.order.limitedQuantity ? 'Yes' : 'No'))

    const otherVehicleSpecs = computed(() => {
      return Object.entries(VEHICLE_SPECS_MAP)
        .filter(([key]) => props.order[key])
        .map(([, value]) => value)
        .join(' | ')
    })

    const otherCountableVehicleSpecs = computed(() => {
      const countableSpecsEntries = Object.entries(COUNTABLE_VEHICLE_SPECS_MAP)
        .filter(([key]) => props.order[key])
        .map(([key, value]) => ({ key, value, count: props.order[key] as number }))
        .filter(({ count }) => count > 0)

      return countableSpecsEntries || []
    })

    const getMappedFuelType = (
      fuelType: string | undefined,
      hasCMRole: boolean
    ): string => {
      if (!fuelType || fuelType === 'undefined') return ''
      return hasCMRole && fuelType.startsWith('hvoflex_')
        ? fuelTypeMappings.diesel
        : fuelTypeMappings[fuelType] || ''
    }

    onMounted(async () => {
      const userUUID = user.value?.uuid
      const hasCMRole = userUUID ? await checkCMRole(userUUID) : false

      vehicleFuelType.value = getMappedFuelType(props.order.fuelType, hasCMRole)
    })

    return {
      preferredVehicleType,
      allowedVehicles,
      adrClasses,
      vehicleFuelType,
      placeholderIfEmpty,
      otherVehicleSpecs,
      otherCountableVehicleSpecs,
      isOrderSidebarVehicleFuelTypeEnabled,
      limitedQuantity,
      startCase
    }
  }
})
