import { Shipment, ShipmentRoute } from '@/types/shipment'
import { shipmentClient } from '@/api/clients/shipment.client'
import { SHIPMENT_API_URL, SHIPMENT_ROUTING_API_URL } from '@/config'
import { genericClient } from '../clients/generic.client'

export async function getShipmentByExternalId(externalId: string): Promise<Shipment> {
  const response = await shipmentClient.get<{ shipment: Shipment }>(
    `${SHIPMENT_API_URL}/v3/shipments?external_id=${externalId}`
  )

  return response.data.shipment
}

export async function getShipmentByUuid(uuid: string): Promise<Shipment> {
  const response = await shipmentClient.get<{ shipment: Shipment }>(
    `${SHIPMENT_API_URL}/v2/shipments/${uuid}`
  )

  return response.data.shipment
}

export async function getShipmentRoutes(shipmentId: string): Promise<ShipmentRoute[]> {
  const response = await genericClient.get<{ shipment_routes: ShipmentRoute[] }>(
    `${SHIPMENT_ROUTING_API_URL}/api/shipment-routes?shipment_id=${shipmentId}`
  )

  return response.data.shipment_routes
}
