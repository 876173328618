import { render, staticRenderFns } from "./CarrierSection.vue?vue&type=template&id=0a7fbb67&scoped=true&"
import script from "./CarrierSection.vue?vue&type=script&lang=ts&"
export * from "./CarrierSection.vue?vue&type=script&lang=ts&"
import style0 from "./CarrierSection.vue?vue&type=style&index=0&id=0a7fbb67&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a7fbb67",
  null
  
)

export default component.exports