import { monitorError } from '@/analytics/monitoring'
import { getDriver, getTrailer, getTruck } from '@/api/services/fleet.service'
import { getTransportPlanDetails } from '@/api/services/transport-planning.service'
import { Driver, Trailer, Truck } from '@/types/fleet'
import {
  ASSET_TYPE,
  TRANSPORT_PLAN_STATE,
  TransportPlanDetails
} from '@/types/transport-planning'
import { computed, ref } from '@vue/composition-api'
import { AxiosError } from 'axios'
import { defineStore } from 'pinia'

export const useTransportPlanningStore = defineStore('transport-planning-store', () => {
  // state
  const loaders = ref<string[]>([])
  const transportPlanDetails = ref<TransportPlanDetails | null>(null)
  const truck = ref<Truck | null>(null)
  const trailer = ref<Trailer | null>(null)
  const driver = ref<Driver | null>(null)

  // getters
  const isLoading = computed<boolean>(() => loaders.value.length > 0)
  const isPlanConfirmed = computed<boolean>(
    () =>
      transportPlanDetails.value?.data.transport_plan_state ===
      TRANSPORT_PLAN_STATE.CONFIRMED
  )

  // actions
  async function fetchTransportPlanDetails(externalId: string, showLoader = true) {
    try {
      if (showLoader) loaders.value.push('transportPlan')
      transportPlanDetails.value = await getTransportPlanDetails(externalId)
    } catch (err: unknown) {
      // transport plan may not exist for the shipment, this is not an error
      if ((err as AxiosError).response?.status === 404) return

      monitorError(
        new Error('Failed to fetch transport plan details'),
        'octopus-new-store',
        {
          err
        }
      )

      throw err
    } finally {
      loaders.value = loaders.value.filter(loader => loader !== 'transportPlan')
    }
  }

  async function fetchTruckDetails(truckId: string, carrierId: string) {
    try {
      loaders.value.push('truck')
      truck.value = await getTruck(truckId, carrierId)
    } catch (err) {
      monitorError(new Error('Failed to fetch truck details'), 'octopus-new-store', {
        err
      })

      throw err
    } finally {
      loaders.value = loaders.value.filter(loader => loader !== 'truck')
    }
  }

  async function fetchTrailerDetails(trailerId: string, carrierId: string) {
    try {
      loaders.value.push('trailer')
      trailer.value = await getTrailer(trailerId, carrierId)
    } catch (err) {
      monitorError(new Error('Failed to fetch truck details'), 'octopus-new-store', {
        err
      })

      throw err
    } finally {
      loaders.value = loaders.value.filter(loader => loader !== 'trailer')
    }
  }

  async function fetchDriverDetails(driverId: string, carrierId: string) {
    try {
      loaders.value.push('driver')
      driver.value = await getDriver(driverId, carrierId)
    } catch (err) {
      monitorError(new Error('Failed to fetch truck details'), 'octopus-new-store', {
        err
      })

      throw err
    } finally {
      loaders.value = loaders.value.filter(loader => loader !== 'driver')
    }
  }

  async function fetchTransportPlanWithDetailsWithAssets(
    externalId: string,
    carrierId: string
  ) {
    try {
      await fetchTransportPlanDetails(externalId, false)

      if (!transportPlanDetails.value) return

      await Promise.all(
        transportPlanDetails.value.data.allocations.map(allocation => {
          if (allocation.asset_type === ASSET_TYPE.TRUCK) {
            return fetchTruckDetails(allocation.asset_id, carrierId)
          }

          if (allocation.asset_type === ASSET_TYPE.TRAILER) {
            return fetchTrailerDetails(allocation.asset_id, carrierId)
          }

          if (allocation.asset_type === ASSET_TYPE.DRIVER) {
            return fetchDriverDetails(allocation.asset_id, carrierId)
          }
        }) || []
      )
    } catch (err) {
      monitorError(
        new Error('Failed to fetch transport plan with details with assets'),
        'octopus-new-store',
        {
          err
        }
      )

      throw err
    }
  }

  return {
    loaders,
    transportPlanDetails,
    truck,
    trailer,
    driver,
    isPlanConfirmed,
    isLoading,
    fetchTransportPlanDetails,
    fetchTruckDetails,
    fetchTrailerDetails,
    fetchDriverDetails,
    fetchTransportPlanWithDetailsWithAssets
  }
})
