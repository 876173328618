












import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    imgType: {
      type: String as PropType<'search' | 'close'>,
      default: 'search'
    }
  }
})
