/**
 * 🚨🚨🚨🚨🚨 IMPORTANT 🚨🚨🚨🚨🚨
 * 🚨🚨 WHILE ADDING A NEW FEATURE FLAG 🚨🚨
 * 🚨🚨 PLEASE ADD A CORRESPONDING ENTRY IN THE https://gitlab.com/sennder/cross-cutting/octopus-components/one-view-mf-component/-/blob/main/apps/pods/ops_compass/pages/carrier_find/widgets/bidding/BiddingMicrofrontendLoader.vue 🚨🚨
 * 🚨🚨 FILE TOO 🚨🚨
 * 🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨
 */
export const BIDDING_FEATURE_FLAGS = {
  SHOW_MANUAL_SUGGESTED_BASE_COST: 'SHOW_MANUAL-SUGGESTED-BASE-COST',
  IS_SUBMIT_OFFER_ENABLED_FOR_DOMESTIC_CARRIERS_PAID_IN_LOCAL_CURRENCY:
    'ENABLE_SUBMIT-OFFER-FOR-DOMESTIC-CARRIERS-PAID-IN-LOCAL-CURRENCY',
  MAKE_COUNTEROFFER_MANDATORY: 'MAKE_COUNTEROFFER-MANDATORY',
  ENABLE_GET_BIDS_BIDDING_TRASPORT_OFFER_MIGRATION:
    'ENABLE_GET-BIDS-BIDDING-TRASPORT-OFFER-MIGRATION',
  ENABLE_OLD_ORDER_DETAILS_API: 'ENABLE_OLD_ORDER_DETAILS_API'
}
