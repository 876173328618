import { inOneMonth, inSevenDays, today } from '@/controllers/formatted-relative-dates'

export const BOARD_FILTER_ORDER_STATES = [
  'NEW',
  'REGISTERED',
  'CARRIER_LOCKED',
  'DISPATCHED'
]

export const DEFAULT_FILTERS_VALUE = {
  pickupRange: {
    startDate: today() + ' 00:00',
    endDate: inSevenDays() + ' 23:45'
  },
  dropoffRange: {
    startDate: today() + ' 00:00',
    endDate: inOneMonth() + ' 23:45'
  },
  orderStates: ['REGISTERED', 'CARRIER_LOCKED']
}

export const DEFAULT_COPY_BTN_TRANSITION_TIMEOUT = 1500

export const DASHBOARD_FEATURE_FLAGS = {
  ENABLE_CARRIER_ONBOARDING_MERGED_TENANTS: 'ENABLE_CARRIER-ONBOARDING-MERGED-TENANTS'
}
