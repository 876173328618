import { computed, nextTick, Ref, ref } from '@vue/composition-api'
import CostForm from '../components/dispatching/dispatching-form/CostForm.vue'

export default (formWrapper: Ref) => {
  const costForm = ref<typeof CostForm | null>(null)

  const checkAllFormsForErrors = (formRefs: Ref[]): boolean => {
    // reduce goes through all the elements whereas some would end at the first found element
    return formRefs.reduce((result: boolean, formRef) => {
      return formRef.value?.submit().hasErrors || result
    }, false)
  }

  const activeForms = computed<Ref[]>(() => {
    const forms: Ref[] = [costForm]

    return forms
  })

  const hasFormErrors = (): boolean => {
    return checkAllFormsForErrors(activeForms.value)
  }

  const scrollToFirstError = () => {
    return nextTick(() => {
      const errorEl = formWrapper.value.getElementsByClassName('form__errors')[0]
      if (errorEl && errorEl.scrollPathIntoView) {
        errorEl.scrollIntoView({ behavior: 'smooth' })
      }
    })
  }

  const validateForm = (): boolean => {
    if (hasFormErrors()) {
      scrollToFirstError()
      return false
    }
    return true
  }

  return {
    costForm,
    checkAllFormsForErrors,
    hasFormErrors,
    scrollToFirstError,
    validateForm
  }
}
