import { TRANSIT_API_URL } from '@/config'
import { genericClient } from '../clients/generic.client'
import { TourExecution } from '@/types/transit'

export async function getTourExecution(shipment_id: string): Promise<TourExecution> {
  const response = await genericClient.get<TourExecution>(
    `${TRANSIT_API_URL}/api/v1/tour-executions?shipment_id=${shipment_id}`
  )

  return response.data
}
