import { StopoverType } from '@/services/enums'
import { defineComponent, ComputedRef } from '@vue/composition-api'

const NAV_ITEMS = Object.freeze([
  'Registration',
  'Facility Scheduling',
  'Dispatching',
  'Execution',
  'Closing'
] as const)

export type NavItemLabel = typeof NAV_ITEMS[number]

export interface StopLocation {
  id: number
  lat: number
  lng: number
  type: StopoverType
  timezone: string
}

export interface TrackingLocation {
  id: number
  lat: number
  lng: number
  time: string
  rotation: number
}

export interface StopCompletionStatus {
  isDone: boolean
}

// TODO: Move to Plankton
export type Form = ReturnType<typeof defineComponent> & {
  validate(): { hasErrors: boolean }
  submit(): { hasErrors: boolean }
}

// TODO: Move to Plankton
export type ConfirmationDialog = ReturnType<typeof defineComponent> & {
  askConfirmation(): Promise<unknown>
}

interface formFieldValidator<T = unknown> {
  isInvalid: (value: T) => boolean
  errorMessage: string
  inline: boolean
}

// TODO: Move to Plankton
export type FormFieldValidators<T = unknown> = ComputedRef<formFieldValidator<T>[]>

type ShipperContractType = 'REGULAR'

export type VehicleType =
  | 'VAN'
  | 'TRUCK_3_5'
  | 'TRUCK_7_5'
  | 'TRUCK_12'
  | 'TRUCK_40_TAUTLINER'
  | 'TRUCK_40_BOX'
  | 'TRUCK_40_DROP'
  | 'TRUCK_40_FRIGO'
  | 'TRUCK_40_MEGA'
  | 'TRUCK_40_OPEN'
  | 'TRUCK_40_JUMBO'

type TargetState = 'NEW' | 'REGISTERED' | 'CARRIER_LOCKED'

type LoadUnitType =
  | 'Europallets'
  | 'Industrial_pallets'
  | 'Colli'
  | 'Cage_Pallets'
  | 'Parcels'
  | 'Duesseldorfer_pallets'
  | 'Chep_pallets'

export interface AccountOperationManagerUser {
  id: number
  email: string
}

export interface ShipperContract {
  id: number
  lineCode: string
  type: ShipperContractType
  zipCodes: string[]
  manager: AccountOperationManagerUser
  vehicleType: VehicleType
  fallbackVehicleTypes: VehicleType[]
  preferredVehicleType: VehicleType
  orderReferenceNumber: string
  targetOrderState: TargetState
  automaticOrderAssignment: boolean
  assignmentValidityHours: number
  amazonPushPreregister: boolean
  gatehousePushTracking: boolean
  basePrice: number
  distanceKm: number
  loadDescription: string
  loadUnitType: LoadUnitType
  loadWeight: number
  loadWidth: number
  loadHeight: number
  loadLength: number
  loadQuantity: number
  needsPalletExchange: boolean
  arrivalNotification: boolean
  dockLoading: boolean
  directTransfer: boolean
  sealable: boolean
  topLoading: boolean
  sideLoading: boolean
  codeXl: boolean
  isTemperatureControlled: boolean
  loadMinimumTemperature: number
  loadMaximumTemperature: number
}

interface UserDetail {
  id: number
  email: string
}

interface Company {
  id: number
  name: string
}

interface Address {
  id: number
  formatted: string
  postal_code: string
  country_code: string
}

interface Stop {
  reference_number?: string
  notes: string
  type: string
  company: Company
  address: Address
  contact: UserDetail
}

interface Route {
  name: string
  stops: Stop[]
}
export interface Carrier {
  id: number
  name: string
}

export interface CarrierContract {
  manager: UserDetail
  status: string
  validity_start?: string
  validity_end?: string
  contact: UserDetail
  shipper_contract_name: string
  carrier: Carrier
  share: number
  priority: number
  capacity_day_1: number
  capacity_day_2: number
  capacity_day_3: number
  capacity_day_4: number
  capacity_day_5: number
  capacity_day_6: number
  capacity_day_7: number
  baseCost: number
  surcharges: string[]
  dispatched_vehicle_type: string
  license_plate?: string
  trailer_license_plate?: string
  driver_phone_number?: string
  carrier_growth_manager?: string
  total_net_cost: number
}

interface Surcharge {
  type: string
  amount: number
  description: string
}

export interface ShipperContractDetail {
  id: number
  deleted: boolean
  name: string
  type: string
  regularity: string
  manager: UserDetail
  status: string
  validity_start?: string
  validity_end?: string
  contact: UserDetail
  shipper: UserDetail
  postal_codes: string[]
  routes: Route[]
  carrierContracts: CarrierContract[]
  preferred_vehicle_type: string
  fallback_vehicle_types: string[]
  order_reference_number?: string
  target_order_state: string
  automatic_order_assignment: boolean
  assignment_validity_hours: number
  amazon_push_preregister?: string
  gatehouse_push_tracking?: string
  agheera_push_location?: string
  base_price: number
  surcharges: Surcharge[]
  distance_km: number
  load_description: string
  load_unit_type: string
  load_weight: number
  load_width: number
  load_height: number
  load_length: number
  load_quantity: number
  arrival_notification: boolean
  dock_loading: boolean
  direct_transfer: boolean
  sealable: boolean
  top_loading: boolean
  side_loading: boolean
  code_xl: boolean
  is_temperature_controlled?: boolean
  load_minimum_temperature?: boolean
  load_maximum_temperature?: boolean
  cascading_method: string
  custom_name: string
  salesforce_tour_id?: string
  runtime_awarded_volume?: number
  owner?: UserDetail
  total_net_price: number
}

export interface CancellationReason {
  id: number
  name: string
  label: string
  description: string
  requiresComment: boolean
}

export interface CancellationPayload {
  reason: number
  comment: string
}
