import { hasHadState, OrderState } from '@/modules/common/order-states'
import { getLogger } from '@/shell/datadog-logger'
import {
  polling,
  PollingCompletionReason,
  PollingResult
} from '@sennder/senn-node-toolkit/lib/tools/polling'
import { Ref } from '@vue/composition-api'
import * as orderingService from '@/services/ordering-service'
import { ORDER_STATE_POLLING_TIMEOUT } from '@/modules/ordering/components/dispatching/constants'

export const getPollOrderState = (
  orderId: number,
  currentOrderState: OrderState | undefined,
  targetOrderState: OrderState,
  onSuccess: () => Promise<void>,
  stopOrderStatePooling: Ref<() => void>
) => {
  const logger = getLogger()

  const orderStatePoolingCompletionHandlers = {
    error: async (results: PollingResult<OrderState | undefined>[]): Promise<void> => {
      logger.error(
        `[TransportPlanning] Polling failed after ${results.length} attempts`,
        results
      )
    },
    timeout: async (results: PollingResult<OrderState | undefined>[]): Promise<void> => {
      logger.error(
        `[TransportPlanning] Polling stopped by timeout after ${results.length} attempts`,
        results
      )
    },
    condition: async (): Promise<void> => {
      await onSuccess()
    },
    'stopped-by-user': async (): Promise<void> => {}
  }

  const fetchOrderState = async (): Promise<OrderState | undefined> => {
    if (
      currentOrderState != undefined &&
      hasHadState(currentOrderState, targetOrderState)
    ) {
      return currentOrderState
    }
    const fetchedState = await orderingService.fetchOrderState(orderId)
    return fetchedState
  }

  const pollOrderState = () => {
    const { stopPolling } = polling<OrderState | undefined>(() => fetchOrderState(), {
      timeout: ORDER_STATE_POLLING_TIMEOUT,
      stopCallback: (result: PollingResult<OrderState | undefined>) =>
        result.type == 'ok' &&
        result.payload != undefined &&
        hasHadState(result.payload, targetOrderState),
      onPollingCompleted: async (
        results: PollingResult<OrderState | undefined>[],
        reason: PollingCompletionReason
      ): Promise<void> => orderStatePoolingCompletionHandlers[reason](results)
    })
    stopOrderStatePooling.value = stopPolling
  }

  return { pollOrderState }
}
